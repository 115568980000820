import React, {useState, useEffect} from 'react'
import styles from './Timeline.module.css';

export default function Timeline({steps}) {

    const [width, setWidth] = useState('');

    useEffect(() => {
        setWidth((95/(steps.length )) + '%');
    }, [steps.length])

    function getClassName(p, i) {
        if(p.status === 'done') {
            if(i + 1 < steps.length && steps[i + 1].status === 'pending') {
                return styles.last;
            } else {
                return styles.active
            }
        } else if(p.status === 'current') {
            if(i + 1 < steps.length && steps[i + 1].status === 'done') {
                return `${styles.active}  ${styles.current}`
            } else {
                return styles.current;
            }
        }
        return ''
    }

    return (
        <div>
            <ul className={styles.Timeline}>
                <li key={0}
                    className={getClassName(steps[0], 0)}
                    style={{width}}
                    data-content={0 + 1}>
                </li>
                <li key={1}
                    className={getClassName(steps[1], 1)}
                    style={{width}}
                    data-content={1 + 1}>
                </li>
                <li key={2}
                    className={getClassName(steps[2], 2)}
                    style={{width}}
                    data-content={2 + 1}>
                </li>
                <li key={3}
                    className={getClassName(steps[3], 3)}
                    style={{width}}
                    data-content={3 + 1}>
                </li>
                <li key={4}
                    className={getClassName(steps[4], 4)}
                    style={{width}}
                    data-content={4 + 1}>
                </li>
                {/*{                    
                    steps.map((p, i) => (
                        <li key={i}
                            // className={`${isDone(p) ? styles.active : ''} ${isCurrent(p) ? styles.current : ''}`}
                            className={getClassName(p, i)}
                            style={{width}}
                            data-content={i + 1}>
                        </li>
                    ))
                }*/}
            </ul>
        </div>
    )

}