import axios from 'axios';

//const url = process.env.REACT_APP_HOST;
const url = 'https://apibeta.mudanza.firmamex.com'
//const url = 'https://9f1c792cbd8c.ngrok.io'
// const url = 'http://localhost:9000'

function backend(path) {
    return `${url}${path}`
}

function buildGet() {
    let source = axios.CancelToken.source();
    return {
        source,
        get: function(path) {
            return axios.get(backend(path), {
                cancelToken: source.token
            });
        }
    }
}

function httpGet(path) {
    console.log('get: ' + path)    
    return axios.get(backend(path));
}

async function httpPost(path, body) {
    console.log('post: ' + path)
    return axios.post(backend(path), body);
}

export  {
    backend,
    httpGet,
    httpPost,
    buildGet
}
