import React, { useState, useEffect } from 'react'
import '../App.css';
import SolidButton from '../components/SolidButton'
import ReturnSolidButton from '../components/ReturnSolidButton'
import { httpGet, httpPost, buildGet } from '../shared/Config';
import { FormGroup, Label, Input, Button, Form, Row, Col, Container } from 'reactstrap'
import { ReactComponent as Informacion } from '../iconos/Informacion.svg'
import { ReactComponent as Fragil } from '../iconos/fragil.svg'
import SummaryCollapse from '../components/SummaryCollapse';

export default function Inventario({UUID, onNext, onBack, dataStep}) {
    //updateMuebles, muebles,*/}
    const [gotInfo, setGotInfo] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const [data, setData] = useState({
        mueblesSeleccionados: dataStep.mueblesSeleccionados,
        m3: dataStep.m3,
        valorDeclarado: dataStep.valorDeclarado
    });

    const [categoriaActiva, setCategoriaActive] = useState(1);

    const [categoriasRows, setCategoriasRows] = useState([]);

    const [muebleCustom, setMuebleCustom] = useState({
        mueble: '',
        peso: 0,
        m3: 0,
        ancho: 0,
        alto: 0,
        largo: 0,
        cantidad: 1,
        fragil: false,
        custom: true,
        vehiculo: false
    });

    function handleCustomChange(name) {
        return function(event) {
            setMuebleCustom({...muebleCustom, [name]:event.target.value});
        }
    }

    function handleChange(name) {
        return function(event) {
            setData({...data, [name]:event.target.value});
        }
    }

    const [categorias, setCategorias] = useState([]);
    const [muebles, setMuebles] = useState([]);
    const [mueblesCustom, setMueblesCustom] = useState([]);
    const [customUrl, setCustomUrl] = useState('');
    const [customMenu, setCustomMenu] = useState(false);

    useEffect(() => {
        // muebles
        let source = null;

        async function fetchMuebles() {
            try {
                const http = buildGet();
                source = http.source;
                const response = await http.get(`/getMuebles`);
                let mueblesDataGet = response.data;
    
                for(let muebleSeleccionado of data.mueblesSeleccionados) {
                    let muebleEncontrado = mueblesDataGet.find(x => x.id === muebleSeleccionado.id);
                    if(muebleEncontrado) {
                        muebleEncontrado.cantidad = muebleSeleccionado.cantidad;
                    }
                }
                setMuebles(mueblesDataGet.map(u => 
                    ({...u, ...u})
                ));
            } catch(e){

            }
        }

        if(!source) {
            fetchMuebles();
        }

        return () => {
            if(source) {
                source.cancel();
                source = null;
            }
        }

    }, [])

    useEffect(() => {
        // muebles custom
        let source = null;

        async function fetchMueblesCustom() {
            try {
                const http = buildGet();
                source = http.source;
                let uuidLocal = localStorage.getItem('json');
                let jsonLocal = JSON.parse(uuidLocal);

                const responseCustom = await http.get(`/getMueblesCustom/?UUID=`+jsonLocal.UUID);
                setMueblesCustom(responseCustom.data.map(u => 
                    ({...u, ...u})
                ));

            } catch(e){

            }
        }

        if(!source) {
            fetchMueblesCustom();
        }

        return () => {
            if(source) {
                source.cancel();
                source = null;
            }
        }

    }, [])

    useEffect(() => {
        // cats
        let source = null;

        async function fetchCats() {
            try {
                const http = buildGet();
                source = http.source;
                const responseCat = await http.get(`/getCategorias`);
            
                const rowsCat = [...Array(Math.ceil(responseCat.data.length / 4))];
                const catOrdered = rowsCat.map((row, idx) => responseCat.data.slice(idx * 4, idx * 4 + 4));

                setCategorias(catOrdered);
                /*setCategorias(catOrdered.map((u, i) => 
                    ( <div key={i}>
                    { u.map((categoria, d) => 
                        <div key={(d+1)*(i+1)}>
                            <span style={{fontSize: '13px', margin:'5px'}}>{categoria.categoria}</span>
                            <img style={{ width:'5vw', height: '5vw'}} alt={d} src={categoria.imagenUrl}/>
                        </div> )}
                    </div> )*/

                //setCategorias(responseCat.data.map(u =>({...u, ...u})));

            } catch(e){

            }
        }

        if(!source) {
            fetchCats();
        }

        return () => {
            if(source) {
                source.cancel();
                source = null;
            }
        }

    }, [])

    useEffect(() => {
        // custom
        let source = null;

        async function fetchCustom() {
            try {
                const http = buildGet();
                source = http.source;
                const responseCustomBlob = await http.get('/getCustomBlob');
                setCustomUrl(responseCustomBlob.data.value);
                setGotInfo(true);

            } catch(e){

            }
        }

        if(!source) {
            fetchCustom();
        }

        return () => {
            if(source) {
                source.cancel();
                source = null;
            }
        }

    }, [])


    /*useEffect(() => {
        let finalMuebles = muebles.map(item => ({...item}));
        let m3Totales = 0;

        finalMuebles = finalMuebles.filter(function(mueble) {
            let cantidad = parseInt(mueble.cantidad);
            if(cantidad > 0) {
                m3Totales += parseInt(mueble.cantidad) * parseInt(mueble.m3);
            }
            return cantidad !== 0;
        });

        setData({...data, mueblesSeleccionados: finalMuebles, m3: m3Totales});

        if(muebles.length > 0) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [muebles]);*/

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        onNext({
            component: (
                <React.Fragment>
                    <SummaryCollapse title="Ver Inventario de Muebles">
                        {
                            data.mueblesSeleccionados.map((u, i) => (   
                                    <Row key={i}>
                                        <Col xs={9} className="SummaryTitle" style={{textTransform:'capitalize'}}>
                                            {u.mueble}
                                        </Col>
                                        <Col xs={3} className="SummaryData">
                                            {u.cantidad}
                                        </Col>
                                    </Row>
                            ))
                        }
                    </SummaryCollapse>
                    <div className="Separator"></div>
                </React.Fragment>
            ),
            data
        })
        //let finalMuebles = muebles.concat(muebleCustom);
        //setData({...data, mueblesSeleccionados: finalMuebles})
    }

    function goBack(e) {
        e.preventDefault();
        e.stopPropagation();

        onBack({
            component: (
                <Row>
                </Row>
            ),
            data
        })
    }

    async function handleCategoryChange(categoria) {
        /*if(categoria.categoria === "OTROS") {
            //setCustomActivo(true);
        } else {
            //setCustomActivo(false);
        }*/
        setCategoriaActive(categoria.id);
        setCustomMenu(false);
    }

    /*function decreaseAmount(event, mueble) {

        event.preventDefault();
        
        setData(mueblesArr => {
            const n = [...mueblesArr];
            const muebleId = mueblesArr.findIndex(m => m.id === mueble.id);
            let currCantidad = n[muebleId].cantidad;

            console.log("-1");

            if(currCantidad === 1 && mueble.categoriaId === customId) {
                n.splice(muebleId, 1);
                return n;
            } else 
            if(currCantidad > 0) {
                n[muebleId] = {...mueblesArr[muebleId], cantidad: currCantidad - 1}
            }

            return n;
        });

        
    }*/

    //function increaseAmount(event, mueble) {
        /*let muebleBusqueda = data.mueblesSeleccionados.find(x => x.id === mueble.id)

        if(muebleBusqueda){ // el mueble ya se encuentra en el array
            let cantidad = muebleBusqueda.cantidad;
            let n = cantidad + 1;

            var arrayMueblesCopy = [...data.mueblesSeleccionados];
            
            
        } else { // el mueble no se encuentra en el array
            data.mueblesSeleccionados.push({
                id: mueble.id,
                mueble: mueble.mueble,
                cantidad: 1
            });
        }*/
        
        //event.preventDefault();
        
        /*setMuebles(mueblesArr => {
            var mueblesCopy = [...mueblesArr];
            const muebleId = mueblesArr.findIndex(m => m.id === mueble.id);



            console.log("+1");
            return mueblesCopy;
        });*/
        /*setMuebles(mueblesArr => {
            var mueblesCopy = [...mueblesArr];
            const muebleId = mueblesArr.findIndex(m => m.id === mueble.id);
            let currCantidad = mueblesCopy[muebleId].cantidad;
            const n = [...mueblesArr];
            n[muebleId] = {...mueblesArr[muebleId], cantidad: currCantidad + 1}
            return n;
        });


    }*/

    /*function addCustom(event) {

        let muebleId = Math.max.apply(Math, muebles.map(function(mueble) { return mueble.id; })) + 1;

        let nMueble = {
            id: muebleId,
            mueble: muebleCustom.mueble,
            categoriaId: categoriaActiva,
            dimensiones: muebleCustom.dimensiones,
            imagenUrl: customUrl,
            cantidad: 1
        }

        muebles.push(nMueble);
        // TODO: limpiar customMueble
        setMuebleCustom({
            mueble: '',
            peso: 0,
            ancho: 0,
            alto: 0,
            largo: 0,
            m3: 0
        });
    }*/

    function addCustomV2(event) {

        let muebleId = Math.max.apply(Math, muebles.map(function(mueble) { return mueble.id; })) + 1;
        let m3Calculados = (parseInt(muebleCustom.ancho) * parseInt(muebleCustom.alto) * parseInt(muebleCustom.largo)) / 1000000;
        //let dimensionesConcat = muebleCustom.ancho+"x"+muebleCustom.alto+"x"+muebleCustom.largo;

        let nMueble = {
            id: 0, // Custom no llevan id, para evitar un bug a futuro que sucederia si se agregan mas muebles a la tabla
            // lo que se trata de evitar es que se genere un custom con un id ya existente en la tabla de muebles
            mueble: muebleCustom.mueble,
            categoriaId: categoriaActiva,
            m3: m3Calculados,
            ancho: parseInt(muebleCustom.ancho),
            alto: parseInt(muebleCustom.alto),
            largo: parseInt(muebleCustom.largo),
            cantidad: 1,
            custom: true,
            fragil: muebleCustom.fragil,
            vehiculo: false,
            imagenUrl: customUrl,
            maniobra: 0
        }

        setMueblesCustom(oldMueblesCustom => [...oldMueblesCustom, nMueble]);
        //data.mueblesSeleccionados.push(nMueble);
        // TODO: limpiar customMueble
        setMuebleCustom({
            mueble: '',
            peso: 0,
            m3: 0,
            ancho: 0,
            alto: 0,
            largo: 0,
            cantidad: 1,
            fragil: false,
            custom: true,
            vehiculo: false,
            maniobra: 0
        });
    }

    useEffect(() => {
        let finalMuebles = muebles.concat(mueblesCustom);
        /*setData(dataPrev => {
            const dat = [...dataPrev];
            dat.mueblesSeleccionados = {...dataPrev, mueblesSeleccionados: finalMuebles};
            return dat;
        });*/
        finalMuebles = finalMuebles.filter(function(mueble) {
            let cantidad = parseInt(mueble.cantidad);
            return cantidad !== 0 && !isNaN(cantidad);
        });

        setData({...data, mueblesSeleccionados: finalMuebles})

        if(finalMuebles.length > 0) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [muebles, mueblesCustom]);

    function toggleCustomMenu() {
        let newState = !customMenu;
        setCustomMenu(newState);
    }

    function updateAmountCustom(event, mueble) {
        event.preventDefault();
        let value = event.target.value;

        //updateMuebles(event, mueble);
        setMueblesCustom(mueblesArr => {
            const muebleId = mueblesArr.findIndex(m => m.id === mueble.id);
            const n = [...mueblesArr];
            
            n[muebleId] = {...mueblesArr[muebleId], cantidad: value};
            return n;
        });
    }

    function updateAmount(event, mueble) {
        event.preventDefault();
        let value = event.target.value;

        value = value < 0 ? 0 : value;

        //updateMuebles(event, mueble);
        setMuebles(mueblesArr => {
            const muebleId = mueblesArr.findIndex(m => m.id === mueble.id);
            const n = [...mueblesArr];
            
            n[muebleId] = {...mueblesArr[muebleId], cantidad: parseInt(value)};
            return n;
        });
    }

    function handleChangeFragilCheckbox() {
        return function(event) {
            let fragilCheck = !muebleCustom.fragil;
            setMuebleCustom({...muebleCustom, fragil: fragilCheck});
        }
    }

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit}>
                <h2 style={{ marginBottom: '15px', color: '#666666' }}>Inventario</h2>
                {
                    gotInfo ? 
                        <React.Fragment>
                        {
                            categorias.map((u, i) => {
                                return <Row style={{justifyContent: 'center'}} key={i+1}>
                                    {u.map((cat, d) => {
                                        return <Col className={`Category OptionArea ${categoriaActiva === cat.id ? 'Selected' : ''}`} onClick={() => handleCategoryChange(cat)} key={(d+1)*(i+1)}>
                                            <span style={{fontSize: '13px', margin:'5px'}}>{cat.categoria}</span>
                                            <img style={{ width:'80%'}} alt={i+1} src={cat.imagenUrl}/>
                                        </Col>
                                    })}
                                </Row>
                            })
                        }
                        <br />
                        <Row className="Inventario">
                        {
                            muebles.map((u, i) => {
                                if(u.categoriaId === categoriaActiva) {
                                    return <React.Fragment key={i+1}>
                                        <div className="ItemInventario">
                                            <div style={{position: 'relative', top: '0', left: '0'}}>
                                                {
                                                    u.fragil ? <React.Fragment><Fragil style={{ transform:'scale(0.7)', position: 'absolute', zIndex: '9000', right: '-10px', top:'-10px'}}></Fragil></React.Fragment> : null
                                                }
                                                <img style={{ width: '5vw', height: '5vw', maxWidth: '65px', transform:'scale(0.8)'}} alt={i+1} src={u.imagenUrl}/>
                                            </div>
                                            <span>{u.mueble}</span>
                                            <div className="Cantidad">
                                                <div>Cantidad</div>
                                                <Input type="number" onChange={(e) => updateAmount(e, u)} style={{width: '70px'}} value={u.cantidad} />
                                            </div>                                            
                                        </div>
                                    </React.Fragment>
                                } else {
                                    return null;
                                }
                            })
                        }
                        {
                            mueblesCustom.map((u, i) => {
                                if(u.categoriaId === categoriaActiva) {
                                    return <React.Fragment key={i+1}>
                                        <div className="ItemInventario">
                                            <div style={{position: 'relative', top: '0', left: '0'}}>
                                                {
                                                    u.fragil ? <React.Fragment><Fragil style={{ transform:'scale(0.7)', position: 'absolute', zIndex: '9000', right: '0px'}}></Fragil></React.Fragment> : null
                                                }
                                                <img style={{ transform:'scale(0.8)'}} alt={i+1} src={u.imagenUrl}/>
                                            </div>
                                            <span>{u.mueble}</span>
                                            <div className="Cantidad">
                                                <div>Cantidad</div>
                                                <Input type="number" onChange={(e) => updateAmountCustom(e, u)} style={{width: '70px'}} value={u.cantidad} />
                                            </div>
                                        </div>
                                    </React.Fragment>
                                } else {
                                    return null;
                                }
                            })
                        }
                        {
                            customUrl ? <React.Fragment>
                                    <div className="ItemInventario Custom" onClick={() => toggleCustomMenu()}>
                                        <div>
                                            <span>AGREGAR OTRO</span>
                                        </div>
                                    </div>
                            </React.Fragment> : null
                        }
                        </Row>
                        <Row>
                        {
                            customMenu ? <React.Fragment>
                                <Container>
                                    <Row style={{marginTop:'10px'}}>
                                        <Col>
                                            <Label>Nombre</Label>
                                            <Input value={muebleCustom.mueble || ''} onChange={handleCustomChange('mueble')}></Input>
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop:'10px'}}>
                                        <Col>
                                            <Label>Peso</Label>
                                            <Input type="number" value={muebleCustom.peso} onChange={handleCustomChange('peso')}></Input>
                                        </Col>
                                        <Col>
                                            <Label>Ancho (cm)</Label>
                                            <Input type="number" value={muebleCustom.ancho} onChange={handleCustomChange('ancho')}></Input>
                                        </Col>
                                        <Col>
                                            <Label>Alto (cm)</Label>
                                            <Input type="number" value={muebleCustom.alto} onChange={handleCustomChange('alto')}></Input>
                                        </Col>
                                        <Col>
                                            <Label>Largo (cm)</Label>
                                            <Input type="number" value={muebleCustom.largo} onChange={handleCustomChange('largo')}></Input>
                                        </Col>
                                    </Row>
                                    <Row style={{marginLeft: '10px',marginTop:'10px'}}>
                                        <Col>
                                            <Input type="checkbox" value={muebleCustom.fragil} onChange={handleChangeFragilCheckbox()} name="fragilCheckbox" checked={muebleCustom.fragil}/>{' '}
                                            Fragil
                                        </Col>
                                    </Row>
                                    <Row style={{marginTop: '10px', marginBottom: '10px'}}>
                                        <Col>
                                            <div className="text-center">
                                                <SolidButton onClick={(e) => addCustomV2(e)}>AGREGAR</SolidButton>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </React.Fragment> : null
                        }
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{color: '#859899', fontSize: '16px'}}>Valor Declarado</Label>
                                    <Input type="number" style={{width: `100%`}} value={data.valorDeclarado} onChange={handleChange("valorDeclarado")} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </React.Fragment> : null
                }
                <Row style={{ alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
                    <ReturnSolidButton onClick={goBack}>REGRESAR</ReturnSolidButton>
                    <span style={{margin: '5px'}}></span>
                    <SolidButton disabled={disabled} type="submit">SIGUIENTE</SolidButton>
                </Row>
            </Form>
        </React.Fragment>
    )
}