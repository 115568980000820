import React, { useState, useEffect } from 'react'
import SolidButton from '../components/SolidButton'
import ReturnSolidButton from '../components/ReturnSolidButton'
import { FormGroup, Label, Input, Form, Row, Col, Tooltip, Container, Spinner  } from 'reactstrap';
import { ReactComponent as CompartidaActive2 } from '../iconos/CompartidaSelected2.svg';
import { ReactComponent as CompartidaInactive2 } from '../iconos/CompartidaInactive2.svg';
import { ReactComponent as ExclusivaActive2 } from '../iconos/ExclusivaSelected2.svg';
import { ReactComponent as ExclusivaInactive2 } from '../iconos/ExclusivaInactive2.svg';
import { ReactComponent as PremiumActive } from '../iconos/Premium Selected.svg';
import { ReactComponent as PremiumInactive } from '../iconos/Premium Inactive.svg';
import { ReactComponent as Informacion } from '../iconos/Informacion.svg';
import { httpGet, httpPost } from '../shared/Config';
import NumberFormat from 'react-number-format';
import Email from '../iconos/Email.svg'
import Help from '../iconos/Help.svg'
import Phone from '../iconos/Phone.svg'

export default function TipoN({onOption, onBack, dataStep, finalDataInfo, cotizacionCompletadaCheck}) {


    const [cotizacionCompletada, setCotizacionCompletada] = useState(false);

    const [disabledButton, setDisabledButton] = useState(true);

    const [showCompartido, setShowCompartido] = useState(false);
    const [showExclusivo, setShowExclusivo] = useState(false);
    const [showPremium, setShowPremium] = useState(false);

    const [totalesListos, setTotalesListos] = useState(false);
    const [error, setError] = useState(false);

    const [mensajeError, setMensajeError] = useState('');
    const [compartidoNoAplica, setCompartidoNoAplica] = useState(false);
    const [premiumNoAplica, setPremiumNoAplica] = useState(false);


    const [data, setData] = useState({
        tipo: dataStep.tipo,
        email: dataStep.email
    });

    /*function handleChange(name) {
        return function(event) {
            setData({...data, [name]:event.target.value});
        }
    }*/

    /*const [finalData, setFinalData] = useState({
        totalCompartido: finalDataInfo.totalCompartido,
        totalExclusivo: finalDataInfo.totalExclusivo,
        totalPremium: finalDataInfo.totalPremium
    });*/

    /*useEffect(() => {
        if(finalData.totalCompartido !== 0 && finalData.totalExclusivo !== 0 && finalData.totalPremium !== 0) {
            setTotalesListos(true);

            async function fetchCostoMinimo() {
                const response = await httpGet(`/getMuebles`);

                if(finalData.totalCompartido < response.data) {
                    setCostoMinimo(true);
                } else {
                    setCostoMinimo(false);
                }
            }
            fetchCostoMinimo();
        }
    }, [finalData]);*/

    function handleChange(tipoMudanza) {
        
        return function(event) {
            console.log({tipoMudanza})
                
            if(tipoMudanza === "Compartido" && !compartidoNoAplica){
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form-sent-opcion-1'});
                }

                onOption({...data, tipo:tipoMudanza});

                setShowCompartido(true);
                setShowExclusivo(false);
                setShowPremium(false);
            } else if(tipoMudanza === "Exclusivo") {
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form-sent-opcion-2'});
                }

                
                onOption({...data, tipo:tipoMudanza});

                setShowExclusivo(true);
                setShowPremium(false);
                setShowCompartido(false);
            } else if(tipoMudanza === "Premium" && !premiumNoAplica) {
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form-sent-opcion-3'});
                }
                
                onOption({...data, tipo:tipoMudanza});
                setShowCompartido(false);
                setShowExclusivo(false);
                setShowPremium(true);
            }
        }
    }

    function handleSubmit(e){
        e.preventDefault();
        e.stopPropagation();

        // onOption(data);
    }


    /*useEffect(() => {
        
        if(data.tipo) {
            setShowCompartido(false);
            setShowExclusivo(false);
            setShowPremium(false);
    
            if(data.tipo === "Compartido"){
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form-sent-opcion-1'});
                }
                setShowCompartido(true);
            } else if(data.tipo=== "Exclusivo") {
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form-sent-opcion-2'});
                }
                setShowExclusivo(true);
            } else if(data.tipo === "Premium") {
                if(window.dataLayer) {
                    window.dataLayer.push({'event': 'form-sent-opcion-3'});
                }
                setShowPremium(true);
            }
            
        }
    }, [data]);*/
    
    /*function handleSubmit(e) {

        if(window.dataLayer) {
            window.dataLayer.push({'event': 'form-sent-Seguinte'});
        }
        
        onNext({
            component: (
                <React.Fragment>
                    <Row>
                        <Col className="SummaryTitle">
                            Tipo de mudanza
                        </Col>
                        <Col className="SummaryData">{data.tipo}</Col>
                    </Row>
                    <div className="Separator"></div>
                </React.Fragment>
            ),
            data
        })
    }*/

    function goBack(e) {
        e.preventDefault();
        e.stopPropagation();

        setShowExclusivo(false);
        setShowPremium(false);
        setShowCompartido(false);
        
        onBack({
            component: (
                <Row>
                </Row>
            ),
            data
        })
    }

    const [compartidoTooltip, setCompartidoTooltip] = useState(false);
    const compartidoToggle = () => setCompartidoTooltip(!compartidoTooltip);

    const [exclusivaTooltip, setExclusivaTooltip] = useState(false);
    const exclusivaToggle = () => setExclusivaTooltip(!exclusivaTooltip);

    /*useEffect(() => {
        if(data.tipo === "Compartido") {
            setShowCompartido(true);

            if(data.mesCompartido && data.semanaCompartido) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }

        } else if (data.tipo === "Exclusivo") {
            setShowExclusivo(true);

            if(data.fechaExclusivo && data.horaExclusivo) {
                setDisabledButton(false);
            } else {
                setDisabledButton(true);
            }
        } else {
            setDisabledButton(true);
        }
    }, [data]);*/

    useEffect(() => {
        if(cotizacionCompletadaCheck) {
            setCotizacionCompletada(true);
            // setWorking(false);
            //localStorage.removeItem('json');
        } else {
            setCotizacionCompletada(false);
        }
    }, [cotizacionCompletadaCheck]);

    useEffect(() => {
        if(finalDataInfo.general) {
            
            setTotalesListos(true);

            if(finalDataInfo.completado && finalDataInfo.totalCompartido === 0) {
                setCompartidoNoAplica(true);
            } else if(finalDataInfo.completado && finalDataInfo.totalPremium === 0) {
                setPremiumNoAplica(true);
            }

            if(finalDataInfo.general.tipo === "Compartido") {
                
                //if(finalDataInfo.tipoTransbordador !== 0) {
                    // transbordador detectado, no se puede seleccionar Compartida
                    //setMensajeError("La mudanza cuenta con al menos un servicio de transbordador, mudanza Compartida no esta disponible.");
                    //setError(true);
                    //setDisabledButton(true);
                //} else {
                
                async function fetchCostoMinimo() {
                    const responseMinimo = await httpPost(`/getConf`, { conf: "MINIMO_M3_COMPARTIDO_TRANSBORDADOR" });
                    let resultMinimo = responseMinimo.data[0];

                    async function fetchCompartidoMaximo() {
                        const responseMaximo = await httpPost(`/getConf`, { conf: "MAXIMO_COMPARTIDO" });
                        let resultMaximo = responseMaximo.data[0];

                        if(finalDataInfo.m3Totales < parseInt(resultMinimo.value)) {
                            setMensajeError("La mudanza no cumple con el minimo de carga para poder seleccionar tipo Compartida");
                            setError(true);
                            setDisabledButton(true);
                        } else if (finalDataInfo.m3Totales > parseInt(resultMaximo.value)) {
                            setMensajeError("La mudanza sobrepasa el limite de carga para poder seleccionar tipo Compartida.");
                            setError(true);
                            setDisabledButton(true);
                        } else {
                            setError(false);
                            setShowCompartido(true)
    
                            // // Tambien revisa que finalData no tenga valores en 0
                            // if(finalDataInfo.general.tipo && finalDataInfo.completado) {
                            //     setDisabledButton(false);
                            //     debugger
                            //     onOption(data);
                            // } else {
                            //     setDisabledButton(true);
                            // }
                        }

                    }
                    
                    fetchCompartidoMaximo();

                    /*if(finalDataInfo.totalCompartido > result.value) {
                        setCostoMinimo(true);
                        
                        // Tambien revisa que finalData no tenga valores en 0
                        if(data.tipo && finalDataInfo.totalCompartido && finalDataInfo.totalExclusivo && finalDataInfo.totalPremium) {
                            setDisabledButton(false);
                        } else {
                            setDisabledButton(true);
                        }
                    } else {
                        setCostoMinimo(false);
                        setDisabledButton(true);
                    }*/
                }

                fetchCostoMinimo();
            } else if(finalDataInfo.general.tipo === "Exclusivo"){
                setShowExclusivo(true)
                setError(false);

                if(finalDataInfo.general.tipo && finalDataInfo.completado) {
                    setDisabledButton(false);
                    // onOption(data);
                } else {
                    setDisabledButton(true);
                }
            } else if(finalDataInfo.general.tipo === "Premium") {
                //setError(false);

                /*if(data.tipo && finalDataInfo.completado) {
                    setDisabledButton(false);
                } else {
                    setDisabledButton(true);
                }*/

                async function fetchPremiumMinimo() {
                    const responseMinimoPremium = await httpPost(`/getConf`, { conf: "MINIMO_PREMIUM" });
                    let resultMinimoP = responseMinimoPremium.data[0];
                    if(finalDataInfo.m3Totales < parseInt(resultMinimoP.value) && resultMinimoP) {
                        setMensajeError("La mudanza no cumple con el minimo de carga para poder seleccionar tipo Premium");
                        setError(true);
                        setDisabledButton(true);
                    } else {
                        if(finalDataInfo.general.tipo && finalDataInfo.completado) {
                            setDisabledButton(false);
                            setError(false);
                            setShowPremium(true)
                        } else {
                            setDisabledButton(true);
                        }
                    }
                }

                fetchPremiumMinimo();
            }
        }
    }, [finalDataInfo]);

    return (
        <React.Fragment>
            <div>
                <h2 style={{ marginBottom: '15px', color: '#666666' }}>Tipo de Mudanza</h2>
             
                {
                    cotizacionCompletada ? <React.Fragment>
                        <Col style={{textAlign:'center'}}>
                            <Col style={{width:'80%', margin:'auto'}}>
                                <p>
                                    Se envió tu cotización al correo: <span className="Blue" style={{fontWeight:'bold', textDecoration:'underline'}}>{data.email}</span>
                                    {/*<br />
                                    <span className="Blue" style={{fontWeight:'bold', textDecoration:'underline'}}>{data.email}</span>
                                    <br />
                                    con información sobre tu mudanza*/}
                                </p>
                            </Col>
                        </Col>
                    </React.Fragment> : null
                }
                { 
                finalDataInfo.working !== false || !totalesListos ? <React.Fragment>
                    <br/>
                    <p style={{textAlign:"center"}}>Espera un momento por favor, estamos trabajando</p>
                    <div style={{marginLeft: '50%'}}>                        
                        <Spinner color="primary" />
                    </div>
                </React.Fragment> : <React.Fragment>
                        <Row>
                        <Container onClick={handleChange('Compartido')}  className={`TipoMudanza OptionArea ${showCompartido ? 'Selected' : ''}`}>
                            <Col style={{marginBottom: '15px'}}>
                                <FormGroup check>
                                    <Label check>
                                        <Col>
                                        {
                                            !showCompartido ?
                                            <React.Fragment>
                                                <CompartidaInactive2 className="DesktopImage" style={{ transform:'scale(0.8)', display:'block', left:'35%', top: '15px' }}/>
                                                <b style={{ fontSize: '18px', color: '#666666', display:'block', left: '20%', top: '90px' }}>Mudanza Compartida</b>
                                            </React.Fragment> : null
                                        }
                                        {
                                            showCompartido ?
                                            <React.Fragment>
                                                <CompartidaActive2 className="DesktopImage" style={{ transform:'scale(0.8)', display: 'block', left:'35%', top: '15px' }}/>
                                                <b style={{ fontSize: '18px', color: '#666666', display:'block', left: '20%', top: '90px' }}>Mudanza Compartida</b>
                                            </React.Fragment> : null
                                        }
                                        </Col>
                                        <br/>
                                        <Row style={{margin:0, alignItems:'center'}}>
                                            {/*<Input style={{width: '15px',  height: '1.3em'}} type="radio" value="Compartido" onChange={handleChange('tipo')} name="radioTipo" checked={data.tipo === "Compartido"} required/>{' '}*/}
                                            <div style={{height:'13px'}}>Opción más económica.</div>
                                        </Row>
                                        <br />
                                        <div>Todos tu muebles van protegidos.</div>
                                        <br/>
                                        <div>Tu camión de mudanza comparte espacio con otros servicios</div>                                    
                                        <br/>
                                        <div>El tiempo de entrega es más lento.</div>
                                        <br/>
                                        {
                                            compartidoNoAplica ? <React.Fragment>
                                                Compartido no disponible con estas opciones.
                                            </React.Fragment> :  <React.Fragment>
                                                {
                                                    showCompartido && cotizacionCompletadaCheck ? <React.Fragment>
                                                        Total: <NumberFormat prefix={'$'} thousandSeparator={true} displayType={'text'} value={finalDataInfo.totalCompartido}></NumberFormat>                                         
                                                    </React.Fragment> : null
                                                }
                                            </React.Fragment>
                                        }
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Container>
                        <Container onClick={handleChange('Exclusivo')} className={`TipoMudanza OptionArea ${showExclusivo ? 'Selected' : ''}`}>
                            <Col style={{marginBottom: '15px'}}>
                                <FormGroup  check>
                                    <Label check>
                                        <Col>
                                        {
                                        !showExclusivo ?
                                        <React.Fragment>
                                            <ExclusivaInactive2 className="DesktopImage" style={{ transform:'scale(0.8)', display:'block', left:'35%', top: '15px' }}/>
                                            <b style={{ fontSize: '18px', color: '#666666', display:'block', left: '20%', top: '90px' }}>Mudanza Exclusiva</b>
                                        </React.Fragment> : null
                                        }
                                        {
                                            showExclusivo ?
                                            <React.Fragment>
                                                <ExclusivaActive2 className="DesktopImage" style={{ transform:'scale(0.8)', display:'block', left:'35%', top: '15px' }}/>
                                                <b style={{ fontSize: '18px', color: '#666666', display:'block', left: '20%', top: '90px' }}>Mudanza Exclusiva</b>
                                            </React.Fragment> : null
                                        }
                                        </Col>
                                        <br/>
                                        <Row style={{margin:0, alignItems:'center'}}>
                                            {/*<Input style={{width: '15px',  height: '1.3em'}} type="radio" value="Exclusivo" onChange={handleChange('tipo')} name="radioTipo" checked={data.tipo === "Exclusivo"} required/>{' '}*/}
                                            <div>Todos tus muebles van protegidos y asegurados con emplaye.</div>
                                        </Row>
                                        <br/>
                                    <div>Tu escoges la fecha y hora de tu mudanza.</div>
                                    <br/>
                                    {
                                        showExclusivo && cotizacionCompletadaCheck ? <React.Fragment>
                                            Total: <NumberFormat prefix={'$'} thousandSeparator={true} displayType={'text'} value={finalDataInfo.totalExclusivo}></NumberFormat>                                         
                                        </React.Fragment> : null
                                    }                              
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Container>
                    </Row>
                    <Row>
                        <Container onClick={handleChange('Premium')} className={`TipoMudanza OptionArea ${showPremium ? 'Selected' : ''}`}>
                            <Col style={{marginBottom: '15px'}}>
                                <FormGroup check>
                                    <Label check>
                                        <Col>
                                        {
                                        !showPremium ?
                                        <React.Fragment>
                                            <PremiumInactive className="DesktopImage" style={{ transform:'scale(0.8)', display:'block', left:'35%', top: '15px' }}/>
                                            <b style={{ fontSize: '18px', color: '#666666', display:'block', left: '20%', top: '90px' }}>Mudanza Premium</b>
                                        </React.Fragment> : null
                                        }
                                        {
                                            showPremium ?
                                            <React.Fragment>
                                                <PremiumActive className="DesktopImage" style={{ transform:'scale(0.8)', display:'block', left:'35%', top: '15px' }}/>
                                                <b style={{ fontSize: '18px', color: '#666666', display:'block', left: '20%', top: '90px' }}>Mudanza Premium</b>
                                            </React.Fragment> : null
                                        }
                                        </Col>
                                        <br/>
                                        <Row style={{margin:0, alignItems:'center'}}>
                                            {/*<Input style={{width: '15px',  height: '1.3em'}} type="radio" value="Premium" onChange={handleChange('tipo')} name="radioTipo" checked={data.tipo === "Premium"} required/>{' '}*/}
                                            <div >Todos tus muebles van protegidos y asegurados con emplaye.</div>
                                        </Row>
                                    <br/>
                                    <div>¡No tienes que hacer nada! Nosotros empacamos por ti.</div>
                                    <br/>
                                    <div>Tu escoges la fecha y hora de tu mudanza.</div>
                                    <br/>
                                    <div>No compartes camión con otras mudanzas.</div>
                                    <br/>
                                    {
                                        premiumNoAplica ? <React.Fragment>
                                            Mudanza Premium no disponible con estas opciones
                                        </React.Fragment> :  <React.Fragment>
                                            {
                                                showPremium && cotizacionCompletadaCheck ? <React.Fragment>
                                                    Total: <NumberFormat prefix={'$'} thousandSeparator={true} displayType={'text'} value={finalDataInfo.totalPremium}></NumberFormat>                                         
                                                </React.Fragment> : null
                                            }
                                        </React.Fragment>
                                    }                                 
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Container>
                        {/* <Col style={{marginBottom: '15px'}}>
                        </Col> */}
                    </Row> 
                    </React.Fragment>
                } 
                {
                    totalesListos && error ? <React.Fragment>
                        <span style={{fontWeight: 'bold', color: 'red'}}>{mensajeError}</span>
                        <br />
                    </React.Fragment> : null
                }
                <br />
                <Row style={{ alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
                    <ReturnSolidButton onClick={goBack}>REGRESAR</ReturnSolidButton>
                    <span style={{margin: '5px'}}></span>
                    {/*<SolidButton disabled={disabledButton} type="submit">COMPLETAR COTIZACION</SolidButton>*/}
                </Row>
            </div>
        </React.Fragment>
    )

}
