import React, { useState, useEffect } from 'react';
import './App.css';
import Timeline from './components/Timeline';
import { Container, Row, Col } from 'reactstrap';
import Datos from './steps/Datos';
import TipoN from './steps/TipoN';
import OrigenN from './steps/OrigenN';
import DestinoN from './steps/DestinoN';
import Inventario from './steps/Inventario';
import CotizacionN from './steps/CotizacionN';
import { httpGet, httpPost } from './shared/Config';
import SolidButton from './components/SolidButton'
import ModalCotizacionEnviada from './components/ModalCotizacionEnviada';
import moment from 'moment';

/*const jsonFinal = {
  UUID: '',
  completarCot: { calculateTotal: false, completarCot: false,  quienEmpaca: ''  },
  codigoRecibido: { codigoRecibido: '' },
  contacto: { nombre: '', email: '', telefono: '' },
  trayecto: { origenFull: '', destinoFull: '', kilometros: 0, valorDeclarado: 0 },
  general: { tipo: '',  tarifa: 0, tipoTransbordador: 0, 
    fechaExclusivo: '', horaExclusivo: '', mesCompartido: 0,
    semanaCompartido: 0, quienEmpaca: '' },
  precalculo: { m3Totales: 0, seguro: 0, costoEmpaque: 0, costoDesempaque: 0, 
      costoPisosOrigen: 0, costoDistanciaOrigen: 0, 
      costoVolarOrigen: 0, costoPisosDestino: 0, 
      costoDistanciaDestino: 0, costoVolarDestino: 0 },  
  datos: {
    origen: {
      tipoViviendaOrigen:'', 
      pisosOrigen: 0, distanciaOrigen: 0, volarOrigen: 0, 
      recamaras: 0, recamarasM3: 0, salas: 0, salasM3: 0, 
      cocina: 0, cocinaM3: 0, cuartoTv: 0, cuartoTvM3: 0,
      comedor: 0, comedorM3: 0, cantina: 0, cantinaM3: 0,
      antecomedor: 0, antecomedorM3: 0, cuartoLavado: 0, 
      cuartoLavadoM3: 0, biblioteca: 0, bibliotecaM3: 0,
      terraza: 0, terrazaM3: 0, jardin: 0, jardinM3: 0,
      cuartoJuegos: 0, cuartoJuegosM3: 0, cajasTotales: 0, cajasM3: 0
    },
    destino: {
      tipoViviendaDestino: '',
      pisosDestino: 0, distanciaDestino: 0, volarDestino: 0
    }
  },
  descuento: {
    codigoAplicado: '',
    porcentaje: 0,
    comision: 0,
    cliente: 0,
    inmobiliario: 0,
    descuentoSinEmpaqueDesempaque: 0
  },
  totales: {
    fleteCamionAdicional: 0,
    camionesAdicionales: 0,
    fleteTotal: 0,
    maniobrasCargaTotal: 0,
    maniobrasDescargaTotal:0,
    costoTransbordador: 0,
    subtotal: 0,
    iva: 0,
    total: 0,
    m3Max: 0,
    totalEDCompartido: 0,
    totalSoloCompartido: 0,
    totalEDExclusivo: 0,
    totalSoloExclusivo: 0
  }
}*/

const jsonFinal = {
  UUID: "",
  completarCot: { 
    calculateTotal: false, 
    completarCot: false,
    currentStep: 0,
    oldestStep: 0
  },
  codigoRecibido: { 
    codigoRecibido: "" 
  },
  contacto: {
      nombre: "",
      email: "",
      telefono: ""
  },
  trayecto: {
      origenFull: "",
      destinoFull: "",
      kilometros: 0,
      valorDeclarado: 0
  },
  general: {
      tipo: "",
      tarifa: 0,
      tipoTransbordador: 0,
      semana: 0,
      mes: 0,
      fecha: "",
      hora: "",
      anio: 0,
      camionetas: false,
      metodoPago: "",
      requiereFactura: false
  },
  precalculo: {
      m3Totales: 0
  },
  datos: {
    inventario:  [],
    origen: {
      direccion: "",
      coord: {},
      numeroInterior: "",
      pisos: 0,
      elevadorDisponible: false,
      requiereVolado: false
    },
    destino: {
      direccion: "",
      coord: {},
      numeroInterior: "",
      pisos: 0,
      elevadorDisponible: false,
      requiereVolado: false
    }
  },
  descuento: {
      codigoAplicado: "",
      porcentaje: 0,
      comision: 0,
      cliente: 0,
      inmobiliario: 0,
      descuentoSinEmpaqueDesempaque: 0
  },
  totales: {
      fleteCamionAdicional: 0,
      camionesAdicionales: 0,
      fleteTotal: 0,
      maniobrasCargaTotal: 0,
      maniobrasDescargaTotal: 0,
      costoTransbordador: 0.0,
      subtotal: 0,
      iva: 0,
      total: 0,
      m3Max: 90,
      totalCompartido: 0,
      totalExclusivo: 0,
      totalPremium: 0
  },
  factura: {
    rfc: "",
    metodoPago: "",
    razonSocial: "",
    pais: "",
    municipio: "",
    colonia: "",
    calle: "",
    codigoPostal: "",
    numeroExterior: "",
    numeroInterior: ""
  }

}



function App() {

  const [codigoFijo, setCodigoFijo] = useState('');
  const [codigoFijoActivo, setCodigoFijoActivo] = useState(false);

  const [jsonPost, setJsonPost] = useState(jsonFinal);

  const [cotizacionCompleta, setCotizacionCompleta] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');

 

  const [steps, setSteps] = useState([
    {
      status: 'current',
      
      name: 'Datos',
      component: Datos,
      data: {
        nombre: '',
        email: '',
        telefono: '',
        codigo: ''
      }
    }, {
      status: 'pending',
    name: 'Origen',
    component: OrigenN,
    data: {
      direccion: '',
      coord: {},
      numeroInterior: 0,
      pisos: 1,
      elevadorDisponible: false,
      requiereVolado: false
    }
  }, {
    status: 'pending',
    name: 'Destino',
    component: DestinoN,
    data: {
      direccion: '',
      coord: {},
      numeroInterior: 0,
      pisos: 1,
      elevadorDisponible: false,
      requiereVolado: false,
      distancia: 0,
      origenFull: ''
    }
  },{
    status: 'pending',
    name: 'Inventario',
    component: Inventario,
    data: {
      mueblesSeleccionados: [],
      m3: 0,
      valorDeclarado: 0
    }
  }, {
    status: 'pending',
    name: 'Tipo',
    component: TipoN,
    data: {
      tipo: '',
      email: ''
    }
  },{
    lastStep: 0,
    currentStep: 0
  }])

  /*,{
    status: 'pending',
    name: 'Cotizacion',
    component: CotizacionN,
    data: {
      tipo: '',
      total: 0,
      tipoPago: '',
      option: '',
      fecha: '',
      hora: '',
      mes: 0,
      semana: 0,
      rfc: '',
      metodoPago: '',
      razonSocial: '',
      pais: '',
      municipio: '',
      colonia: '',
      calle: '',
      codigoPostal: 0,
      numeroExterior: 0,
      numeroInterior: 0,
      facturaRequerida: false,
      email: ''
    }
  },*/

  const [UUID, setUUID] = useState(false);

  const [showFinal, setShowFinal] = useState(false);
  const [enableFinal, setEnableFinal] = useState(false);
  const [sent, setSent] = useState(false)

  const [estados, setEstados] = useState({});
  const [tipoMudanza, setTipoMudanza] = useState('');

  const [origenFull, setOrigenFull] = useState('');
  const [destinoFull, setDestinoFull] = useState('');

  const [origenCiudad, setOrigenCiudad] = useState('');
  const [destinoCiudad, setDestinoCiudad] = useState('');

  const [idOrigen, setIDOrigen] = useState('');
  const [idDestino, setIDDestino] = useState('');

  const [currentStep, setCurrentStep] = useState(0);

  const [quienEmpaca, setQuienEmpaca] = useState('');

  const [finalSent, setFinalSent] = useState(false);

  const [arrayMuebles, setArrayMuebles] = useState([]);

  const [folio, setFolio] = useState('');
  const [folioDisponible, setFolioDisponible] = useState(false);

  const [finalDataInfo, setFinalDataInfo] = useState({
      working:false,
      totalCompartido: 0,
      totalExclusivo: 0,
      totalPremium: 0,
      tipoTransbordador: 0,
      m3Totales: 0,
      completado: false,
      casoDistancia: 0
  })

  /*const [steps, setSteps] = useState([{
    status: 'current',
    name: 'first',
    component: Datos,
    data: {
      nombre: '',
      email: '',
      telefono: '',
      codigo: ''
    }
  }, {
    status: 'pending',
    name: 'tipo',
    component: Tipo,
    data: {
      tipo: '',
      mesCompartido: 0,
      semanaCompartido: 0,
      fechaExclusivo: '',
      horaExclusivo: ''
    }
  }, {
    status: 'pending',
    name: 'Origen',
    component: Origen,
    data: {
      origenEstado: '',
      origenCiudad: '',
      origenCP: '',
      tipoViviendaOrigen: 'Casa',
      origenNumeroPisos: 0,
      origenDistancia: 0,
      origenVolar: 0,
      costoPisosOrigen: 0,
      costoDistanciaOrigen: 0,
      costoVolarOrigen: 0
    }
  }, {
    status: 'pending',
    name: 'Destino',
    component: Destino,
    data : {
      destinoEstado: '',
      destinoCiudad: '',
      destubiCP: '',
      tipoViviendaDestino: 'Casa',
      destinoNumeroPisos: 0,
      destinoDistancia: 0,
      destinoVolar: 0,
      costoPisosDestino: 0,
      costoDistanciaDestino: 0,
      costoVolarDestino: 0,
      origenEstado: 0,
      origenCiudad: ''
    }
  }, {
    status: 'pending',
    name: 'Mapa',
    component: Mapa,
    data: {
      origenFull: '',
      destinoFull: '',
      kilometros: 0,
      valorDeclarado: 0,
      seguro: 0,
      tarifa: 0,
      tipoTransbordador: 0,
      tipoMudanza: '',
      origenCiudad: '',
      destinoCiudad: '',
      idOrigen: 0,
      idDestino: 0
    }
  }, {
    status: 'pending',
    name: 'EmpaqueDesempaque',
    component: EmpaqueDesempaque,
    data: {
      cajas: 0,
      cajasM3: 0,
      recamaras: 0,
      recamarasM3: 0,
      comedores: 0,
      comedoresM3: 0,
      cocinas: 0,
      cocinasM3: 0,
      antecomedores: 0,
      antecomedoresM3: 0,
      salas: 0,
      salasM3: 0,
      jardines: 0,
      jardinesM3: 0,
      terrazas: 0,
      terrazasM3: 0,
      cuartosLavado: 0,
      cuartosLavadoM3: 0,
      cuartosTv: 0,
      cuartosTvM3: 0,
      cantinas: 0,
      cantinasM3: 0,
      cuartosJuego: 0,
      cuartosJuegoM3: 0,
      bibliotecas: 0,
      bibliotecasM3: 0,
      costoEmpaque: 0,
      costoDesempaque: 0,
      m3Totales: 0,
      tipoMudanza: '',
      fechaExclusivo: '',
      horaExclusivo: ''
    }
  }, {
    status: 'pending',
    name: 'Cotizacion',
    component: Cotizacion,
    data: {
      option: '',
      totalED: 0,
      totalSolo: 0,
      enableFinal: false
    }
  }, {
    lastStep: 0,
    currentStep: 0
  }])*/

  const libraries = ['places'];


  const [stepsCheck, setStepsCheck] = useState({
    lastStep: 0,
    currentStep: 0
  });

  // [ {component, data} ]
  const [finalCalculate, setFinalCalculate] = useState(false);
  const [stepResults, setStepResults] = useState([]);

  const [oldestStep, setOldestStep] = useState(0);
  /*useEffect(() => {
    async function  createProfile() {
      const response = await httpGet(`/generateProfile`)
      let jsonResponse = JSON.parse(response.data);
      setUUID(jsonResponse.uuid);
      setFolio(jsonResponse.folio);
      setFolioDisponible(true);
      /*setJsonPost({...jsonPost,
        completarCot: { UUID: response.data }
      });*/

    //}
    //createProfile();

  //}, []);*/

  /*useEffect(() => {

    setSteps(prev => {
      //const current = prev.findIndex(s => s.status === 'current');
      const newSteps = [...prev];

      //let current = stepResults[stepResults.length - 1];
      //let lastStep = current - 1;
      //if(currentStep > 0) {
        //let current = prev[7].currentStep;
        //newSteps[current].status = 'done';
        //let previous = currentStep - 1;
        //newSteps[previous].status = 'done';

      if(stepResults[stepsCheck.lastStep]) {
        newSteps[stepsCheck.lastStep].data = stepResults[stepsCheck.lastStep].data;
      }

      if(stepsCheck.currentStep === 3) {
        newSteps[stepsCheck.currentStep].data.origenEstado = idOrigen;
        newSteps[stepsCheck.currentStep].data.origenCiudad = origenCiudad;
      } else if(stepsCheck.currentStep === 4) {
        newSteps[stepsCheck.currentStep].data.origenFull = origenFull;
        newSteps[stepsCheck.currentStep].data.destinoFull = destinoFull;
        newSteps[stepsCheck.currentStep].data.tipoMudanza = tipoMudanza;
        newSteps[stepsCheck.currentStep].data.origenCiudad = origenCiudad;
        newSteps[stepsCheck.currentStep].data.destinoCiudad = destinoCiudad;
        newSteps[stepsCheck.currentStep].data.idOrigen = idOrigen;
        newSteps[stepsCheck.currentStep].data.idDestino = idDestino;
      } else if(stepsCheck.currentStep === 5) {
        newSteps[stepsCheck.currentStep].data.tipoMudanza = tipoMudanza;
      }

      newSteps[stepsCheck.currentStep].status = 'current';
      newSteps[stepsCheck.lastStep].status = 'done';
      //}
      
      return newSteps;
    });

  }, [stepResults, stepsCheck, destinoFull, origenFull, tipoMudanza, origenCiudad, destinoCiudad, idOrigen, idDestino]);*/
  //

  /*useEffect(() => {

    setSteps(prev => {
      //const current = prev.findIndex(s => s.status === 'current');
      const newSteps = [...prev];

      if(stepResults[stepsCheck.lastStep]) {
        newSteps[stepsCheck.lastStep].data = stepResults[stepsCheck.lastStep].data;
      }

      if(stepsCheck.lastStep !== stepsCheck.currentStep){
        newSteps[stepsCheck.currentStep].status = 'current';
        newSteps[stepsCheck.lastStep].status = 'done';
      }
        
      //}
      
      return newSteps;
    });

  }, [stepResults, stepsCheck]);*/

  useEffect(() => {

    /*async function getMuebles(){
    const response = await httpGet(`/getMuebles`);
        setMuebles(response.data.map(u => 
            ({...u, ...u})
        ));
    }
    getMuebles();*/

    let search = window.location.search;
    let params = new URLSearchParams(search);
    let codigoFijoValue = params.get('c');

    if(codigoFijoValue) {
      setCodigoFijo(codigoFijoValue);
      setCodigoFijoActivo(true);

      setSteps(prev => {
        const newSteps = [...prev];
        newSteps[5].data.codigo = codigoFijoValue;
        return newSteps;
      });

    } else {
      setCodigoFijoActivo(false);
    }  

    let json = localStorage.getItem('json');
    if(json) {
      let jsonLocal = JSON.parse(json);
      jsonLocal.completarCot.currentStep = 1;  // reset step count
      setJsonPost(jsonLocal);
      setUUID(jsonLocal.UUID);
      async function getFolio() {
        try{
          const response = await httpGet(`/getFolio/?UUID=`+jsonLocal.UUID);
          if(response) {
            if(response.data === "no se encontro el UUID") {  // error, resetear app 
              reiniciar();
            } else {
              setFolio(response.data);
              setFolioDisponible(true);
            }
          }
        } catch(error) {
          //let errorParse = JSON.parse(error);
          console.log(error);
        }
      }

      getFolio();

      setSteps(prev => {
        const newSteps = [...prev];
        newSteps[1].data.direccion = jsonLocal.datos.origen.direccion;
        newSteps[1].data.coord = jsonLocal.datos.origen.coord;
        newSteps[1].data.numeroInterior = jsonLocal.datos.origen.numeroInterior;
        newSteps[1].data.pisos = jsonLocal.datos.origen.pisos;
        newSteps[1].data.elevadorDisponible = jsonLocal.datos.origen.elevadorDisponible;
        newSteps[1].data.requiereVolado = jsonLocal.datos.origen.requiereVolado;

        newSteps[2].data.direccion = jsonLocal.datos.destino.direccion;
        newSteps[2].data.coord = jsonLocal.datos.destino.coord;
        newSteps[2].data.numeroInterior = jsonLocal.datos.destino.numeroInterior;
        newSteps[2].data.pisos = jsonLocal.datos.destino.pisos;
        newSteps[2].data.elevadorDisponible = jsonLocal.datos.destino.elevadorDisponible;
        newSteps[2].data.requiereVolado = jsonLocal.datos.destino.requiereVolado;

        newSteps[3].data.mueblesSeleccionados = jsonLocal.datos.inventario;
        newSteps[3].data.m3 = jsonLocal.precalculo.m3Totales;
        newSteps[3].data.valorDeclarado = jsonLocal.trayecto.valorDeclarado;

        newSteps[4].data.tipo = jsonLocal.general.tipo;
        newSteps[4].data.email = jsonLocal.general.email;

        newSteps[0].data.nombre = jsonLocal.contacto.nombre;
        newSteps[0].data.email = jsonLocal.contacto.email;
        newSteps[0].data.telefono = jsonLocal.contacto.telefono;
        newSteps[0].data.codigo = jsonLocal.descuento.codigoAplicado;

        //newSteps[5].data.tipo = jsonLocal.general.tipo;

        //newSteps[5].data.total = 0;

        // COMENTADO PARA QUE SE VUELVA A CALCULAR TODO
        /*if(jsonLocal.general.tipo === "Compartido") {
          newSteps[5].data.total = jsonLocal.totales.totalCompartido;
        } else if(jsonLocal.general.tipo === "Exclusivo") {
          newSteps[5].data.total = jsonLocal.totales.totalExclusivo;
        } else if(jsonLocal.general.tipo === "Premium") {
          newSteps[5].data.total = jsonLocal.totales.totalPremium;
        }*/

        /*newSteps[5].data.tipoPago = jsonLocal.general.metodoPago;
        if(jsonLocal.general.fecha) {
          newSteps[5].data.fecha = moment(jsonLocal.general.fecha).toDate();
        } else {
          newSteps[5].data.fecha = null;
        }
        newSteps[5].data.hora = jsonLocal.general.hora;
        newSteps[5].data.mes = jsonLocal.general.mes;
        newSteps[5].data.semana = jsonLocal.general.semana;
        newSteps[5].data.anio = jsonLocal.general.anio;

        // factura
        newSteps[5].data.rfc = jsonLocal.factura.rfc;
        newSteps[5].data.metodoPago = jsonLocal.factura.metodoPago;
        newSteps[5].data.razonSocial = jsonLocal.factura.razonSocial;
        newSteps[5].data.pais = jsonLocal.factura.pais;
        newSteps[5].data.municipio = jsonLocal.factura.municipio;
        newSteps[5].data.colonia = jsonLocal.factura.colonia;
        newSteps[5].data.calle = jsonLocal.factura.calle;
        newSteps[5].data.codigoPostal = jsonLocal.factura.codigoPostal;
        newSteps[5].data.numeroExterior = jsonLocal.factura.numeroExterior;
        newSteps[5].data.numeroInterior = jsonLocal.factura.numeroInterior;
        newSteps[5].data.facturaRequerida = jsonLocal.general.requiereFactura;
        newSteps[5].data.email = jsonLocal.contacto.email;*/

        return newSteps;
      });

    } else {
      async function  createProfile() {
        try {
          const response = await httpGet(`/generateProfile`)

          let jsonResponse = JSON.parse(response.data);
          setUUID(jsonResponse.uuid);
          setFolio(jsonResponse.folio);
          setFolioDisponible(true);
  
          let varJson = jsonFinal;
          varJson.UUID = jsonResponse.uuid;
  
          localStorage.setItem('json', JSON.stringify(varJson));

        } catch (error) {
          console.log(error);
        }
        /*setJsonPost({...jsonPost,
          completarCot: { UUID: response.data }
        });*/
      }
      createProfile();
    }

  }, []);

  useEffect(() => {

    if(!UUID) {
      return;
    }

    let currentStepValue = steps[5].currentStep;
    let lastStep =  steps[5].lastStep;

    // if(steps[currentStep].status === 'done') {
    //   return;
    // }

    setJsonPost(post => {

      var oldestStepCheck = post.completarCot.oldestStep;

      if(oldestStepCheck <= currentStepValue) {
        oldestStepCheck = currentStepValue;
      }

      if(currentStepValue > lastStep) { // se dio un paso hacia adelante

        if(currentStepValue === 2) {

          let direccion = steps[lastStep].data.direccion ? steps[lastStep].data.direccion : '';
          let coord = steps[lastStep].data.coord ? steps[lastStep].data.coord : '';
          let numeroInterior = steps[lastStep].data.numeroInterior ? steps[lastStep].data.numeroInterior : '';
          let pisos = steps[lastStep].data.pisos ? parseInt(steps[lastStep].data.pisos) : 1;
          let elevadorDisponible = steps[lastStep].data.elevadorDisponible ? steps[lastStep].data.elevadorDisponible : false;
          let requiereVolado = steps[lastStep].data.requiereVolado ? steps[lastStep].data.requiereVolado : false;
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, currentStep: currentStepValue, oldestStep: oldestStepCheck },
            trayecto: { ...post.trayecto, origenFull: direccion },
            datos: { ...post.datos,
              origen: {
                ...post.datos.origen, direccion, coord, numeroInterior, pisos, elevadorDisponible, requiereVolado
              }
            }
          }
          
        } else if(currentStepValue === 3) {

          let direccion = steps[lastStep].data.direccion ? steps[lastStep].data.direccion : '';
          let coord = steps[lastStep].data.coord ? steps[lastStep].data.coord : '';
          let numeroInterior = steps[lastStep].data.numeroInterior ? steps[lastStep].data.numeroInterior : '';
          let pisos = steps[lastStep].data.pisos ? parseInt(steps[lastStep].data.pisos) : 1;
          let elevadorDisponible = steps[lastStep].data.elevadorDisponible ? steps[lastStep].data.elevadorDisponible : false;
          let requiereVolado = steps[lastStep].data.requiereVolado ? steps[lastStep].data.requiereVolado : false;
          let distancia = steps[lastStep].data.distancia ? parseInt(steps[lastStep].data.distancia) : 0;
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, currentStep: currentStepValue, oldestStep: oldestStepCheck },
            trayecto: { ...post.trayecto, distancia, destinoFull: direccion },
            datos:{ ...post.datos,
              destino: {
                ...post.datos.destino, direccion, coord, numeroInterior, pisos, elevadorDisponible, requiereVolado
              }
            },
          }
  
        } else if (currentStepValue === 4) {
  
          let inventario = steps[lastStep].data.mueblesSeleccionados ? steps[lastStep].data.mueblesSeleccionados : [];
          let m3 = steps[lastStep].data.m3 ? parseInt(steps[lastStep].data.m3) : 0;
          let valorDeclarado = steps[lastStep].data.valorDeclarado ? parseInt(steps[lastStep].data.valorDeclarado) : 0;

          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: true, completarCot: false, currentStep: currentStepValue, oldestStep: oldestStepCheck  },
            trayecto: { ...post.trayecto, valorDeclarado },
            precalculo: { ...post.precalculo, m3Totales: m3 },
            datos: { ...post.datos,
              inventario
            }
          }
  
        } /*else if(currentStepValue === 5) {
  
          let tipo = steps[lastStep].data.tipo ? steps[lastStep].data.tipo : '';
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, currentStep: currentStepValue, oldestStep: oldestStepCheck  },
            general: { ...post.general, tipo }
          }
  
        } */ else if(currentStepValue === 1) {
  
          let nombreValue = steps[lastStep].data.nombre ? steps[lastStep].data.nombre : '';
          let emailValue = steps[lastStep].data.email ? steps[lastStep].data.email : '';
          let telefonoValue = steps[lastStep].data.telefono ? steps[lastStep].data.telefono : '';
          let codigoValue = steps[lastStep].data.codigo ? steps[lastStep].data.codigo : '';
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, currentStep: currentStepValue, oldestStep: oldestStepCheck  },
            codigoRecibido: { ...post.codigoRecibido, codigoRecibido: codigoValue },
            contacto: { ...post.contacto, nombre: nombreValue, email: emailValue, telefono: telefonoValue }
          }
  
        }/* else if(currentStepValue === 6) {

          return {...post, 
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, currentStep: currentStepValue, oldestStep: oldestStepCheck  }
          }

        }*/
      }

      return post;
    });

  }, [steps, UUID]);


  /*useEffect(() => {

    if(!UUID) {
      return;
    }

    if(!estados) {
      return;
    }


    setJsonPost(post => {
      //let passStep = 0;
      let quienEmpacaValue = quienEmpaca ? quienEmpaca : null;

      //let currentStepValue = steps.findIndex(s => s.status === "current");
      let currentStepValue = steps[7].currentStep;
      let lastStep =  steps[7].lastStep;

      // REVISAR SI EL PASO FUE ADELANTE O ATRAS, RESTA O SUMA

      if(currentStepValue > lastStep) { // se dio un paso hacia adelante

        if(currentStepValue === 1) {

          let nombreValue = steps[lastStep].data.nombre ? steps[lastStep].data.nombre : '';
          let emailValue = steps[lastStep].data.email ? steps[lastStep].data.email : '';
          let telefonoValue = steps[lastStep].data.telefono ? steps[lastStep].data.telefono : '';
          let codigoValue = steps[lastStep].data.codigo ? steps[lastStep].data.codigo : '';
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, quienEmpaca: quienEmpacaValue },
            codigoRecibido: { ...post.codigoRecibido, codigoRecibido: codigoValue },
            contacto: { ...post.contacto, nombre: nombreValue, email: emailValue, telefono: telefonoValue }
          }
          
        } else if(currentStepValue === 2) {
  
          let mesCompartidoValue = steps[lastStep].data.mesCompartido ? parseInt(steps[lastStep].data.mesCompartido) : 0;
          let semanaCompartidoValue = steps[lastStep].data.semanaCompartido ? parseInt(steps[lastStep].data.semanaCompartido) : 0;
          let fechaExclusivoValue = steps[lastStep].data.fechaExclusivo ? steps[lastStep].data.fechaExclusivo : '';
          let horaExclusivoValue = steps[lastStep].data.horaExclusivo ? steps[lastStep].data.horaExclusivo : '';
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, quienEmpaca: quienEmpacaValue },
            general: { ...post.general, tipo: steps[lastStep].data.tipo, mesCompartido: mesCompartidoValue, semanaCompartido: semanaCompartidoValue,
              fechaExclusivo: fechaExclusivoValue, horaExclusivo: horaExclusivoValue
            } 
          }
  
        } else if (currentStepValue === 3) {
  
          let tipoViviendaOrigenValue = steps[lastStep].data.tipoViviendaOrigen ? steps[lastStep].data.tipoViviendaOrigen : '';
          let pisosOrigenValue = steps[lastStep].data.origenNumeroPisos ? parseInt(steps[lastStep].data.origenNumeroPisos) : 0;
          let distanciaOrigenValue = steps[lastStep].data.origenDistancia ? parseInt(steps[lastStep].data.origenDistancia) : 0;
          let volarOrigenValue = steps[lastStep].data.origenVolar ? parseInt(steps[lastStep].data.origenVolar) : 0;
  
          let costoPisosOrigenValue = steps[lastStep].data.costoPisosOrigen ? parseInt(steps[lastStep].data.costoPisosOrigen) : 0;
          let costoDistanciaOrigenValue = steps[lastStep].data.costoDistanciaOrigen ? parseInt(steps[lastStep].data.costoDistanciaOrigen) : 0;
          let costoVolarOrigenValue = steps[lastStep].data.costoVolarOrigen ? parseInt(steps[lastStep].data.costoVolarOrigen) : 0;
  
          let origenEstadoInt = steps[lastStep].data.origenEstado ? parseInt(steps[lastStep].data.origenEstado) : 0;
          let origenCiudadValue = steps[lastStep].data.origenCiudad ? steps[lastStep].data.origenCiudad : '';
          let origenCPValue = steps[lastStep].data.origenCP ? parseInt(steps[lastStep].data.origenCP) : 0;
  
          let origenEstadoValue = null;
          
          if(estados[origenEstadoInt - 1].estado) {
            origenEstadoValue = estados[origenEstadoInt - 1].estado;
          }
  
          let origenFullValue = "";
  
          if(origenEstadoValue) {
            origenFullValue += origenEstadoValue+" ";
          }
  
          if(origenCiudadValue) {
            origenFullValue += origenCiudadValue+" ";
          }
  
          if(origenCPValue) {
            origenFullValue += origenCPValue+" ";
          }
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, quienEmpaca: quienEmpacaValue },
            trayecto: { ...post.trayecto, origenFull: origenFullValue },
            precalculo: { ...post.precalculo, costoPisosOrigen: costoPisosOrigenValue, costoDistanciaOrigen: costoDistanciaOrigenValue, costoVolarOrigen: costoVolarOrigenValue },
            datos: { ...post.datos,
              origen: {
                ...post.datos.origen, tipoViviendaOrigen: tipoViviendaOrigenValue, pisosOrigen: pisosOrigenValue, distanciaOrigen: distanciaOrigenValue, volarOrigen: volarOrigenValue
              }
            }
          }
  
  
        } else if(currentStepValue === 4) {
  
          let tipoViviendaDestinoValue = steps[lastStep].data.tipoViviendaDestino ? steps[lastStep].data.tipoViviendaDestino : '';
          let pisosDestinoValue = steps[lastStep].data.destinoNumeroPisos ? parseInt(steps[lastStep].data.destinoNumeroPisos) : 0;
          let distanciaDestinoValue = steps[lastStep].data.destinoDistancia ? parseInt(steps[lastStep].data.destinoDistancia) : 0;
          let volarDestinoValue = steps[lastStep].data.destinoVolar ? parseInt(steps[lastStep].data.destinoVolar) : 0;
  
          let costoPisosDestinoValue = steps[lastStep].data.costoPisosDestino ? parseInt(steps[lastStep].data.costoPisosDestino) : 0;
          let costoDistanciaDestinoValue = steps[lastStep].data.costoDistanciaDestino ? parseInt(steps[lastStep].data.costoDistanciaDestino) : 0;
          let costoVolarDestinoValue = steps[lastStep].data.costoVolarDestino ? parseInt(steps[lastStep].data.costoVolarDestino) : 0;
  
          let destinoEstadoInt = steps[lastStep].data.destinoEstado ? parseInt(steps[lastStep].data.destinoEstado) : 0;
          let destinoCiudadValue = steps[lastStep].data.destinoCiudad ? steps[lastStep].data.destinoCiudad : '';
          let destinoCPValue = steps[lastStep].data.destinoCP ? parseInt(steps[lastStep].data.destinoCP) : 0;
  
          let destinoEstadoValue = null;
          
          if(estados[destinoEstadoInt - 1].estado) {
            destinoEstadoValue = estados[destinoEstadoInt - 1].estado;
          }
  
          let destinoFullValue = "";
  
          if(destinoEstadoValue) {
            destinoFullValue += destinoEstadoValue+" ";
          }
  
          if(destinoCiudadValue) {
            destinoFullValue += destinoCiudadValue+" ";
          }
  
          if(destinoCPValue) {
            destinoFullValue += destinoCPValue+" ";
          }
  
          return {...post,
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, quienEmpaca: quienEmpacaValue },
            trayecto: { ...post.trayecto, destinoFull: destinoFullValue },
            precalculo: { ...post.precalculo, costoPisosDestino: costoPisosDestinoValue, costoDistanciaDestino: costoDistanciaDestinoValue, costoVolarDestino: costoVolarDestinoValue },
            datos: { ...post.datos,
              destino: { ...post.datos.destino,
                tipoViviendaDestino: tipoViviendaDestinoValue, pisosDestino: pisosDestinoValue, distanciaDestino: distanciaDestinoValue, volarDestino: volarDestinoValue
              }
            }
          }
  
        } else if(currentStepValue === 5) {
  
          //trayecto: { origenFull: null, destinoFull: null, kilometros: null, valorDeclarado: null },
          let origenFullValue = steps[lastStep].data.origenFull ? steps[lastStep].data.origenFull : ''
          let destinoFullValue = steps[lastStep].data.destinoFull ? steps[lastStep].data.destinoFull : '';
          let kilometrosValue = steps[lastStep].data.kilometros ? parseInt(steps[lastStep].data.kilometros) : 0;
          let valorDeclaradoValue = steps[lastStep].data.valorDeclarado ? parseInt(steps[lastStep].data.valorDeclarado) : 0;
          let tipoTransbordadorValue = steps[lastStep].data.tipoTransbordador ? parseInt(steps[lastStep].data.tipoTransbordador) : 0;
          let tarifaValue = steps[lastStep].data.tarifa ? parseInt(steps[lastStep].data.tarifa) : 0;
        
          return {...post, 
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, quienEmpaca: quienEmpacaValue },
            general: { ...post.general, tipoTransbordador: tipoTransbordadorValue, tarifa: tarifaValue },
            trayecto: { ...post.trayecto, origenFull: origenFullValue, destinoFull: destinoFullValue, kilometros: kilometrosValue, valorDeclarado: valorDeclaradoValue }
          }
  
        } else if(currentStepValue === 6) {
  
          let recamarasValue = steps[lastStep].data.recamaras ? parseInt(steps[lastStep].data.recamaras) : 0;
          let recamarasM3Value = steps[lastStep].data.recamarasM3 ? parseInt(steps[lastStep].data.recamarasM3) : 0;
          let comedoresValue = steps[lastStep].data.comedores ? parseInt(steps[lastStep].data.comedores) : 0;
          let comedoresM3Value = steps[lastStep].data.comedoresM3 ? parseInt(steps[lastStep].data.comedoresM3) : 0;
          let cocinasValue = steps[lastStep].data.cocinas ? parseInt(steps[lastStep].data.cocinas) : 0;
          let cocinasM3Value = steps[lastStep].data.cocinasM3 ? parseInt(steps[lastStep].data.cocinasM3) : 0;
          let antecomedoresValue = steps[lastStep].data.antecomedores ? parseInt(steps[lastStep].data.antecomedores) : 0;
          let antecomedoresM3Value = steps[lastStep].data.antecomedoresM3 ? parseInt(steps[lastStep].data.antecomedoresM3) : 0;
          let salasValue = steps[lastStep].data.salas ? parseInt(steps[lastStep].data.salas) : 0;
          let salasM3Value = steps[lastStep].data.salasM3 ? parseInt(steps[lastStep].data.salasM3) : 0;
          let jardinesValue = steps[lastStep].data.jardines ? parseInt(steps[lastStep].data.jardines) : 0;
          let jardinesM3Value = steps[lastStep].data.jardinesM3 ? parseInt(steps[lastStep].data.jardinesM3) : 0;
          let terrazasValue = steps[lastStep].data.terrazas ? parseInt(steps[lastStep].data.terrazas) : 0;
          let terrazasM3Value = steps[lastStep].data.terrazasM3 ? parseInt(steps[lastStep].data.terrazasM3) : 0;
          let cuartosLavadoValue = steps[lastStep].data.cuartosLavado ? parseInt(steps[lastStep].data.cuartosLavado) : 0;
          let cuartosLavadoM3Value = steps[lastStep].data.cuartosLavadoM3 ? parseInt(steps[lastStep].data.cuartosLavadoM3) : 0;
          let cuartosTvValue = steps[lastStep].data.cuartosTv ? parseInt(steps[lastStep].data.cuartosTv) : 0;
          let cuartosTvM3Value = steps[lastStep].data.cuartosTvM3 ? parseInt(steps[lastStep].data.cuartosTvM3) : 0;
          let cantinasValue = steps[lastStep].data.cantinas ? parseInt(steps[lastStep].data.cantinas) : 0;
          let cantinasM3Value = steps[lastStep].data.cantinasM3 ? parseInt(steps[lastStep].data.cantinasM3) : 0;
          let cuartosJuegoValue = steps[lastStep].data.cuartosJuego ? parseInt(steps[lastStep].data.cuartosJuego) : 0;
          let cuartosJuegoM3Value = steps[lastStep].data.cuartosJuegoM3 ? parseInt(steps[lastStep].data.cuartosJuegoM3) : 0;
          let bibliotecasValue = steps[lastStep].data.bibliotecas ? parseInt(steps[lastStep].data.bibliotecas) : 0;
          let bibliotecasM3Value = steps[lastStep].data.bibliotecasM3 ? parseInt(steps[lastStep].data.bibliotecasM3) : 0;
          let cajasValue = steps[lastStep].data.cajas ? parseInt(steps[lastStep].data.cajas) : 0;
          let cajasM3Value = steps[lastStep].data.cajasM3 ? parseInt(steps[lastStep].data.cajasM3) : 0;
  
          let costoEmpaqueValue = steps[lastStep].data.costoEmpaque ? parseInt(steps[lastStep].data.costoEmpaque) : 0;
          let costoDesempaqueValue = steps[lastStep].data.costoDesempaque ? parseInt(steps[lastStep].data.costoDesempaque) : 0;
  
          let m3TotalesValue = steps[lastStep].data.m3Totales ? parseInt(steps[lastStep].data.m3Totales) : 0;
  
          let tipoMudanzaValue = steps[lastStep].data.tipoMudanza ? steps[lastStep].data.tipoMudanza : '';
  
          let fechaExclusivo = steps[lastStep].data.fechaExclusivo ? steps[lastStep].data.fechaExclusivo : '';
          let horaExclusivo = steps[lastStep].data.horaExclusivo ? steps[lastStep].data.horaExclusivo : '';
  
          if(fechaExclusivo && horaExclusivo) {
            return {...post, 
              UUID: UUID,
              completarCot: { ...post.completarCot, calculateTotal: true, completarCot: false, quienEmpaca: quienEmpacaValue },
              general: { ...post.general,
                tipo: tipoMudanzaValue, fechaExclusivo: fechaExclusivo, horaExclusivo: horaExclusivo, mesCompartido: 0, semanaCompartido: 0
              },
              precalculo: { ...post.precalculo,
                m3Totales: m3TotalesValue, costoEmpaque: costoEmpaqueValue, costoDesempaque: costoDesempaqueValue
              },
              datos: { ...post.datos,
                origen: { ...post.datos.origen,
                  recamaras: recamarasValue, recamarasM3: recamarasM3Value, salas: salasValue, salasM3: salasM3Value, cocina: cocinasValue,
                  cocinaM3: cocinasM3Value, cuartoTv: cuartosTvValue, cuartoTvM3: cuartosTvM3Value, comedor: comedoresValue, comedorM3: comedoresM3Value,
                  cantina: cantinasValue, cantinaM3: cantinasM3Value, antecomedor: antecomedoresValue, antecomedorM3: antecomedoresM3Value,
                  cuartoLavado: cuartosLavadoValue, cuartoLavadoM3: cuartosLavadoM3Value, biblioteca: bibliotecasValue, bibliotecaM3: bibliotecasM3Value,
                  terraza: terrazasValue, terrazaM3: terrazasM3Value, jardin: jardinesValue, jardinM3: jardinesM3Value, cuartoJuegos: cuartosJuegoValue,
                  cuartoJuegosM3: cuartosJuegoM3Value, cajasTotales: cajasValue, cajasM3: cajasM3Value
                }
              }
            }
          } else {
            return {...post, 
              UUID: UUID,
              completarCot: { ...post.completarCot, calculateTotal: true, completarCot: false, quienEmpaca: quienEmpacaValue },
              general: { ...post.general, tipo: tipoMudanzaValue },
              precalculo: { ...post.precalculo,
                m3Totales: m3TotalesValue, costoEmpaque: costoEmpaqueValue, costoDesempaque: costoDesempaqueValue
              },
              datos: { ...post.datos,
                origen: { ...post.datos.origen,
                  recamaras: recamarasValue, recamarasM3: recamarasM3Value, salas: salasValue, salasM3: salasM3Value, cocina: cocinasValue,
                  cocinaM3: cocinasM3Value, cuartoTv: cuartosTvValue, cuartoTvM3: cuartosTvM3Value, comedor: comedoresValue, comedorM3: comedoresM3Value,
                  cantina: cantinasValue, cantinaM3: cantinasM3Value, antecomedor: antecomedoresValue, antecomedorM3: antecomedoresM3Value,
                  cuartoLavado: cuartosLavadoValue, cuartoLavadoM3: cuartosLavadoM3Value, biblioteca: bibliotecasValue, bibliotecaM3: bibliotecasM3Value,
                  terraza: terrazasValue, terrazaM3: terrazasM3Value, jardin: jardinesValue, jardinM3: jardinesM3Value, cuartoJuegos: cuartosJuegoValue,
                  cuartoJuegosM3: cuartosJuegoM3Value, cajasTotales: cajasValue, cajasM3: cajasM3Value
                }
              }
            }
          }
        } else if(currentStepValue === 7) {
          return {...post, 
            UUID: UUID,
            completarCot: { ...post.completarCot, calculateTotal: false, completarCot: false, quienEmpaca: quienEmpacaValue }
          }
        }
      }
      

      return post;
    });

  }, [steps, UUID, estados, quienEmpaca]);*/

  useEffect(() => {
    console.log(stepResults);
  }, [stepResults])


  useEffect(() => {
    async function updateJson() {
      const response = await httpPost(`/updateJson`, jsonPost);
      
      setErrorMessage('');
      /*if(jsonPost.completarCot.calculateTotal) {
        let responseJson = JSON.parse(response.data);

        if(responseJson) {
          let totalCompartido = responseJson.cotizacion.totales.totalCompartido;
          let totalExclusivo = responseJson.cotizacion.totales.totalExclusivo;
          let totalPremium = responseJson.cotizacion.totales.totalPremium;
          
          setFinalDataInfo({
            data: {
              totalCompartido: totalCompartido,
              totalExclusivo: totalExclusivo,
              totalPremium: totalPremium
            }
        })
      }*/


        /*if(responseJson) {
          var totalEDFinal = 0;
          var totalSoloFinal = 0;
    
          if(jsonPost.general.tipo === "Compartido") {
            totalEDFinal = responseJson.cotizacion.totales.totalEDCompartido;
            totalSoloFinal = responseJson.cotizacion.totales.totalSoloCompartido;
          } else if(jsonPost.general.tipo === "Exclusivo"){
            totalEDFinal = responseJson.cotizacion.totales.totalEDExclusivo;
            totalSoloFinal = responseJson.cotizacion.totales.totalSoloExclusivo;
          }
    
          setFinalDataInfo({
            data: {
              totalED: totalEDFinal,
              totalSolo: totalSoloFinal
            }
          });
        }
    
        if(jsonPost.completarCot.completarCot) {
          setSent(true);
        }

        setFinalCalculate(false);*/
      //}
    }

    if(jsonPost && jsonPost.UUID) {
      if(!cotizacionCompleta) {
        localStorage.setItem('json', JSON.stringify(jsonPost));
      }
      updateJson();
    }

    async function fetchCalculateTotal() {      
      const response = await httpPost(`/calculateTotal`, jsonPost);
      if(jsonPost.completarCot.calculateTotal) {
        let responseJson = JSON.parse(response.data);

        if(responseJson) {
            
            if(!responseJson.error){
            let totalCompartido = responseJson.cotizacion.totales.totalCompartido;
            let totalExclusivo = responseJson.cotizacion.totales.totalExclusivo;
            let totalPremium = responseJson.cotizacion.totales.totalPremium;
            let transbordador = responseJson.cotizacion.general.tipoTransbordador;
            
            let m3Totales = responseJson.cotizacion.precalculo.m3Totales;
            
            let casoDistancia = responseJson.cotizacion.general.casoDistancia;


            setFinalDataInfo({              
                working:false,
                general: jsonPost.general,
                totalCompartido: totalCompartido,
                totalExclusivo: totalExclusivo,
                totalPremium: totalPremium,
                tipoTransbordador: transbordador,
                m3Totales: m3Totales,
                completado: true,
                casoDistancia: casoDistancia
            });

            if(jsonPost.completarCot.completarCot) {
              setCotizacionCompleta(true);
            }
          } else {
            setErrorMessage(responseJson.error);
          }
        }
      }
    }

    if(jsonPost.completarCot.currentStep === 4 || jsonPost.completarCot.currentStep === 5) {
      fetchCalculateTotal();
    }

  }, [jsonPost]);

  function CurrentStepComponent(props) {
    return steps[currentStep].component(props);
  }

  function StepResultComponent({index}) {
    return stepResults[index].component;
  }

  function handleClickChangeStep(step) {
    return function(stepResult) {
      //window.scrollTo(0,0)
      // aqui seria actualizar stepresult dependiendo del indice

      var stepResultsCopy = [...stepResults]; // copy of stepResults;
      stepResultsCopy.splice(currentStep, 1, stepResult);

      /*let tipoMudanzaHandle = "";
      let idOrigenHandle = -1;
      let origenFullHandle = "";
      let origenCiudadHandle = "";
      let destinoFullHandle = "";
      let destinoCiudadHandle = "";
      let idDestinoHandle = -1;*/

      if(stepResult === 2) { 
        if(stepResult.data.direccion) {
          setOrigenFull(stepResult.data.direccion);
        }
      } else if(stepResult === 3) {
        if(stepResult.data.direccion) {
          setDestinoFull(stepResult.data.direccion);
        }
      } else if(stepResult === 4) {
        if(stepResult.data.mueblesSeleccionados) {
          setArrayMuebles(stepResult.data.mueblesSeleccionado);
        }
      } else if(stepResult === 5) {
        if(stepResult.data.tipo) {
          setTipoMudanza(stepResult.data.tipo);
        }
      }

      /*stepResultsCopy.push({
        currentStep: step
      });*/

      let lastStep = step - 1;

      /*setStepsCheck({
        lastStep: step,
        currentStep: nextStep
      });*/
      setCurrentStep(step);
      setStepResults(stepResultsCopy);

      setSteps(prev => {
        const newSteps = [...prev];

        /*if(stepResults[lastStep]) {
          newSteps[lastStep].data = stepResults[lastStep].data;
        }*/
        //newSteps[lastStep].data = stepResults[lastStep].data;
        newSteps[lastStep].data = stepResult.data;
        newSteps[lastStep].status = 'done';
  
        newSteps[step].status = 'current';

        if(step === 2) {
          newSteps[step].data.origenFull = stepResult.data.direccion;
        }
        else if(step === 4) {
          newSteps[step].data.email = jsonPost.contacto.email;
          
        } else if(step === 5) {
          newSteps[5].data.tipo = stepResult.data.tipo;

          if(stepResult.data.tipo === "Compartido") {
            newSteps[5].data.total = finalDataInfo.totalCompartido;
          } else if(stepResult.data.tipo === "Exclusivo") {
            newSteps[5].data.total = finalDataInfo.totalExclusivo;
          } else if(stepResult.data.tipo === "Premium") {
            newSteps[5].data.total = finalDataInfo.totalPremium;
          }

          newSteps[5].data.casoDistancia = finalDataInfo.casoDistancia;
        } else if(step === 1) {
          newSteps[1].data.email = stepResult.data.email;
        }

        /*if(step === 3) {
          newSteps[step].data.origenEstado = idOrigenHandle;
          newSteps[step].data.origenCiudad = origenCiudadHandle;
        } else if(step === 4) {
          newSteps[step].data.origenFull = origenFull;
          newSteps[step].data.destinoFull = destinoFullHandle;
          newSteps[step].data.tipoMudanza = tipoMudanza;
          newSteps[step].data.origenCiudad = origenCiudad;
          newSteps[step].data.destinoCiudad = destinoCiudadHandle;
          newSteps[step].data.idOrigen = idOrigen;
          newSteps[step].data.idDestino = idDestinoHandle;
        } else if(step === 5) {
          newSteps[step].data.tipoMudanza = tipoMudanza;
        } else if(step === 6) {
          if(newSteps[1].data.tipo === "Compartido" && stepResult.data.tipoMudanza === "Exclusivo") {
            steps[1].data.tipo = "Exclusivo";
            steps[1].data.mesCompartido = 0;
            steps[1].data.semanaCompartido = 0;
            steps[1].data.fechaExclusivo = stepResult.data.fechaExclusivo;
            steps[1].data.horaExclusivo = stepResult.data.horaExclusivo;
          }
        }*/

        newSteps[5].lastStep = lastStep;
        newSteps[5].currentStep = step;

        return newSteps;
      });
    }
  }

  function handleClickStepBack(step) {
    return function(stepResult) {
      let previousStep = step + 1;
      
      setCurrentStep(step);
      /*setStepsCheck({
        lastStep: previousStep,
        currentStep: step
      });*/

      setSteps(prev => {
        const newSteps = [...prev];
        
        newSteps[previousStep].status = 'pending';
        
        /*if(stepResults[step]) {
          newSteps[step].data = stepResults[step].data;
        }*/

        /*if(step === 3) {
          newSteps[step].data.origenEstado = idOrigen;
          newSteps[step].data.origenCiudad = origenCiudad;
        } else if(step === 4) {
          newSteps[step].data.origenFull = origenFull;
          newSteps[step].data.destinoFull = destinoFull;
          newSteps[step].data.tipoMudanza = tipoMudanza;
          newSteps[step].data.origenCiudad = origenCiudad;
          newSteps[step].data.destinoCiudad = destinoCiudad;
          newSteps[step].data.idOrigen = idOrigen;
          newSteps[step].data.idDestino = idDestino;
        } else if(step === 5) {
          newSteps[step].data.tipoMudanza = tipoMudanza;
        }*/
        if(step === 3) {
          setFinalDataInfo({
              working:false,
              totalCompartido: 0,
              totalExclusivo: 0,
              totalPremium: 0,
              tipoTransbordador: 0,
              m3Totales: 0,
              completado: false,
              casoDistancia: 0
          });

          setCotizacionCompleta(false);
        }
        //newSteps[previousStep].status = 'current';
  
        //newSteps[6].data.option = '';
        newSteps[5].lastStep = previousStep;
        newSteps[5].currentStep = step;

        return newSteps;
      });

    }
  }

  useEffect(() => {
    async function fetchEstados() {
        const response = await httpGet(`/getEstados`)
        setEstados(response.data.map(u => 
          ({...u, ...u})
          ));
    }
    fetchEstados();
  }, []);

  async function calculateTotal(data) {

    /*let rfc = data.rfc ? data.rfc : '';
    let metodoPago = data.metodoPago ? data.metodoPago : '';
    let razonSocial = data.razonSocial ? data.razonSocial : '';
    let pais = data.pais ? data.pais : '';
    let municipio = data.municipio ? data.municipio : '';
    let colonia = data.colonia ? data.colonia : '';
    let calle = data.calle ? data.calle : '';
    let codigoPostal = data.codigoPostal ? parseInt(data.codigoPostal) : 0;
    let numeroExterior = data.numeroExterior ? data.numeroExterior : "";
    let numeroInterior = data.numeroInterior ? data.numeroInterior : "";

    let facturaRequerida = data.facturaRequerida ? data.facturaRequerida : false;

    let mes = data.mes ? parseInt(data.mes) : 0;
    let semana = data.semana ? parseInt(data.semana) : 0;

    let fecha = data.fecha ? data.fecha : '';
    let hora = data.hora ? data.hora : '';

    
    let tipoPagoGeneral = data.tipoPago ? data.tipoPago : '';

    let anio = data.anio ? parseInt(data.anio) : 0;

    // agregar nueva info
    setJsonPost(post => {
      return {
        ...post,
        UUID: UUID,
        general: { ...post.general, requiereFactura: facturaRequerida, mes, semana, fecha, hora, anio, metodoPago: tipoPagoGeneral },
        completarCot: { ...post.completarCot, calculateTotal: true, completarCot: true, currentStep: 6 },
        factura: { ...post.factura, rfc, metodoPago, razonSocial, pais, municipio, colonia, calle, codigoPostal, numeroExterior, numeroInterior }
      }
    })*/

    setFinalDataInfo({
      ...finalDataInfo,
      working: true
    })

    setJsonPost(post => {
      return {
        ...post,
        UUID: UUID,
        general: { ...post.general, tipo: data.tipo },
        completarCot: { ...post.completarCot, calculateTotal: true, completarCot: true, currentStep: 5, oldestStep: 5 },
      }
    })

    /*async function fetchCalculateTotal() {
      const response = await httpPost(`/calculateTotal`, jsonPost);
    }

    fetchCalculateTotal();*/
    //setFinalCalculate(true);
  }
  
  function enableFinalButton(data){
    //setQuienEmpaca(data);
    /*setSteps(prev => {
      const current = prev.findIndex(s => s.name === 'CotizacionN');
      
      const newSteps = [...prev];

      return newSteps;
    });*/

    //setQuienEmpaca(data);
    calculateTotal(data);
  }


  /*async function calculateTotal(step5, completar, quienEmpacaData) {

      setJsonPost(post => {
        return {
          ...post,
          UUID: UUID,
          completarCot: { ...post.completarCot, calculateTotal: true, completarCot: true, quienEmpaca: quienEmpacaData }
        }
      })

      setFinalCalculate(true);
  }*/

  /*function enableFinalButton(data){
    //setQuienEmpaca(data);
    setSteps(prev => {
      const current = prev.findIndex(s => s.name === 'Cotizacion');
      
      const newSteps = [...prev];
      newSteps[current].data.option = data;
      newSteps[current].data.enableFinal = true;

      return newSteps;
    });

    setQuienEmpaca(data);
    calculateTotal(steps[5].data, true, data);
  }*/

  /*function finishCotizacion() {
      calculateTotal(steps[5].data, true);
  }*/

  function handleClickConfirmEnviada() {
    setSent(false);
  }

  function reiniciar() {
    localStorage.removeItem('json');
    window.location.reload();
  }

  /*function updateMuebles(event, mueble){
    event.preventDefault();
    let value = event.target.value;

    setMuebles(mueblesArr => {
        const muebleId = mueblesArr.findIndex(m => m.id === mueble.id);
        const n = [...mueblesArr];
        
        n[muebleId] = {...mueblesArr[muebleId], cantidad: value};
        return n;
    });
  }*/

  return (
    <Container>
      <Row>
        <Col>
          <Timeline steps={steps}></Timeline>
        </Col>
      </Row>
      <Row className="AppContainer">
        <Col className="FormContainer" md={8} xs={12}>
            <CurrentStepComponent 
              dataStep={steps[currentStep] ? steps[currentStep].data : null} 
              finalDataInfo={finalDataInfo ? finalDataInfo : null}
              onNext={handleClickChangeStep(currentStep + 1)} 
              onBack={handleClickStepBack(currentStep - 1)}
              onOption={enableFinalButton}
              cotizacionCompletadaCheck={cotizacionCompleta}
              errorMessage={errorMessage}
            >
              {/*
              muebles={muebles ? muebles : null}
              updateMuebles={updateMuebles}*/}
            </CurrentStepComponent>
        </Col>
        <Col md={4} xs={12}>
          <Container className="SummaryContainer">
            <Col>
            <h2 className="Blue">Tu cotización</h2>
            {
              folioDisponible ? <React.Fragment>
              <span>Folio #{folio}</span>
              
              </React.Fragment> : null
            }
            <Container>
              {
                stepResults.map((sr, i) => (
                  <Row key={i}>
                    <Col>
                      <StepResultComponent index={i}></StepResultComponent>
                    </Col>
                  </Row>
                ))
              }
              {
                sent ?
                <ModalCotizacionEnviada
                titulo={'Cotización enviada'}
                descripcion={
                    <React.Fragment>
                        <p>
                            La cotización ha sido completada y enviada a su correo.
                        </p>
                    </React.Fragment>
                }
                isOpen={true}
                onAceptar={handleClickConfirmEnviada}>
                </ModalCotizacionEnviada> : null
              }
              <br/>
              <SolidButton onClick={reiniciar}>REINICIAR COTIZACION</SolidButton>
            </Container>
            </Col>
          </Container>          
        </Col>
      </Row>
    </Container>
  );
}

export default App;
