import React, { useState, useEffect } from 'react'
import SolidButton from '../components/SolidButton'
import { GoogleMap, LoadScriptNext, Marker, Autocomplete } from '@react-google-maps/api';
import ReturnSolidButton from '../components/ReturnSolidButton'
import { httpGet, httpPost } from '../shared/Config';
import { FormGroup, Label, Input, Form, Row, Col, Tooltip, CustomInput, Container} from 'reactstrap'
import { ReactComponent as Informacion } from '../iconos/Informacion.svg'

import debounce from 'lodash/debounce'

const libraries = ['places'];

export default function DestinoN({onNext, onBack, dataStep}) {

    const [estados, setEstados] = useState({});
    const [estadosSelect, setEstadosSelect] = useState();
    const [ciudadesSelect, setCiudadesSelect] = useState([]);

    const [value, setValue] = useState("");

    let autocomplete;

    const [distancia, setDistancia] = useState("");

    const [disabledButton, setDisabledButton] = useState(true);
    const [data, setData] = useState({
        direccion: dataStep.direccion,
        coord: dataStep.coord,
        numeroInterior: dataStep.numeroInterior,
        pisos: dataStep.pisos,
        elevadorDisponible: dataStep.elevadorDisponible,
        requiereVolado: dataStep.requiereVolado
    })

    const [errorMessage, setErrorMessage] = useState('');
    const [done, setDone] = useState(false);

    const [jsonUbicacion, setJsonUbicacion] = useState(dataStep.direccion);
    const [ubicacion, setUbicacion] = useState('');

    const defaultMapOptions = {
        fullscreenControl: false,
        streetViewControl: false,
        mapTypeControl: false,
    };

    const [destinoCoordinates, setDestinoCoordinates] = useState({
        //id: 1,
        lat: 90,
        lng: -100
    });

    function handleChange(name) {
        return function(event) {
            setData({...data, [name]:event.target.value});
        }
    }

    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        onNext({
            component: (
                <React.Fragment>
                    <Row>
                        <Col>
                            <ul className="SummaryTitle"><li>Destino</li></ul>
                            <Col className="Origen">
                                <div className="SummaryData">
                                    {data.direccion}, {data.numeroInterior}
                                </div>                        
                                <div className="SummaryData">
                                    Pisos: {data.pisos} 
                                </div>                           
                            </Col>                  
                        </Col>
                    </Row>
                    {/*<div className="Separator"></div>
                    <Row>
                        <Col className="SummaryTitle">
                                Km por recorrer
                        </Col>
                        <Col className="SummaryData">
                            {data.distancia}
                        </Col>
                    </Row>*/}
                    <div className="Separator"></div>
                </React.Fragment>
            ),
            data
        })
    }

    function goBack(e) {
        e.preventDefault();
        e.stopPropagation();

        onBack({
            component: (
                <Row>
                    {/*<Col>
                        Origen
                        <br />
                        {data.origenCiudad}, {data.origenEstado}
                        <br />
                        {data.tipoViviendaOrigen}, {data.origenNumeroPisos} pisos, {data.origenDistancia} metros de distancia
                        <br />
                        {data.origenVolar} Muebles a volar
                        <br />
                    </Col>*/}
                </Row>
            ),
            data
        })
    }

    /*useEffect(() => {
        async function fetchEstados() {
            const response = await httpGet(`/getEstados`)
            setEstados(response.data.map(u => 
              ({...u, ...u})
            ));
    
            setEstadosSelect(response.data.map((dataEstados) => <option key={dataEstados.id} value={dataEstados.id}>{dataEstados.estado}</option>));
        }
        fetchEstados();
    }, []);*/

    /*useEffect(() => {
        async function fetchCiudades() {
            const response = await httpPost(`/getCiudades`, { id: data.origenEstado })
            setCiudadesSelect(response.data.map((dataCiudad) => <option key={dataCiudad.id} value={dataCiudad.ciudad}>{dataCiudad.ciudad}</option>));
        }

        if(data.origenEstado && data.origenEstado !== 0) {
            fetchCiudades();
        }
    }, [data.origenEstado])*/

    useEffect(() => {
        //if(data.origenEstado && data.origenCiudad) {
            setDisabledButton(false);
        /*} else {
            setDisabledButton(true);
        }*/
    }, [data]);

    const [center, setCenter] = useState({
        lat: 23.6345,
        lng: -102.5528
    });
      
    const onLoadFunc = marker => {
        console.log('marker: ', marker)
    };

    const onLoadAutocomplete = AutocompleteS => {
        console.log('autocomplete: ', AutocompleteS)
        this.autocomplete = AutocompleteS;
    };

    const onPlacesChanged = (e) => {
        let place = this.autocomplete.getPlace().formatted_address;
        
        if(!place) {
            return;
        }

        
        setErrorMessage('');
        setDone(false);
        //setData({...data, direccion: place});
        setUbicacion(place);
        async function fetchDistance() {
            const responseDestino = await httpPost(`/geolocate`, {place: place})

            if(responseDestino.data) {
                let destinoCoordsCheck = JSON.parse(responseDestino.data);

                setDestinoCoordinates({
                    lat: destinoCoordsCheck.lat,
                    lng: destinoCoordsCheck.lng
                });
                
                setDone(true);
                setJsonUbicacion(place);
                setData({...data, direccion: place, coord: {lat: destinoCoordsCheck.lat, lng: destinoCoordsCheck.lng}});
            }  else {
                setErrorMessage('La ubicación seleccionada no es una dirección valida.');
            }
        }

        fetchDistance();
    };

    function handleKeyDown(e) {
        if(e.keyCode === 13) {
            e.preventDefault();
            e.stopPropagation();
            return false;
        }
    }

    function onUbicacionChange(event) {
        setData({...data, direccion: null});
        setUbicacion(event.target.value);
        /*if(event.target.value){
            if(event.target.value.length > 4) {
                debounceOnUbicacionChange(event.target.value);
            }
        }*/
    }

    const debounceOnUbicacionChange = debounce(event => {
        setDestinoCoordinates({
            lat: 0,
            lng: 0
        });
        
        async function fetchDistance() {
            const responseDestino = await httpPost(`/geolocate`, {place: event})
            let destinoCoordsCheck = JSON.parse(responseDestino.data);

            setDestinoCoordinates({
                lat: destinoCoordsCheck.lat,
                lng: destinoCoordsCheck.lng
            });

            // Calcular distancia
            /*if(data.origenFull && event) {
                const response = await httpPost(`/calcDistance`, { origen: data.origenFull, destino: event })
                let jsonResponse = JSON.parse(response.data);
                
                let distanceParse = JSON.parse(jsonResponse.distance);
                let convertToKm = distanceParse.value / 1000;

                if(convertToKm) {
                    setData({...data, distancia: convertToKm});
                }
            }*/
        }

        fetchDistance();
    }, 2000);

    function handleChangeElevedorDisponible() {
        return function(event) {
            let elevadorDisponibleCheck = !data.elevadorDisponible;
            setData({...data, elevadorDisponible: elevadorDisponibleCheck});
        }
    }

    function handleChangeRequiereVolado() {
        return function(event) {
            let requiereVoladoCheck = !data.requiereVolado
            setData({...data, requiereVolado: requiereVoladoCheck});
        }
    }
    

    useEffect(() => {
        if(jsonUbicacion) {

            if(data.coord.lat && data.coord.lng) {
                    
                setDestinoCoordinates({
                    lat: data.coord.lat,
                    lng: data.coord.lng
                });
            
            }
            setUbicacion(jsonUbicacion);
            setDone(true);
            /*async function fetchDistance() {
                const responseDestino = await httpPost(`/geolocate`, {place: jsonUbicacion})
    
                if(responseDestino.data) {
                    let destinoCoordsCheck = JSON.parse(responseDestino.data);
    
                    setDestinoCoordinates({
                        lat: destinoCoordsCheck.lat,
                        lng: destinoCoordsCheck.lng
                    });
                    
                    setUbicacion(jsonUbicacion);
                    setDone(true);
                }  else {
                    setErrorMessage('La ubicación seleccionada no es una dirección valida.');
                }
            }
    
            fetchDistance();*/
        }
    }, [jsonUbicacion, data.coord]);

    /*function checkUbicacion() {
        if(jsonUbicacion) {
            setUbicacion(jsonUbicacion);
            async function fetchDistance() {
                const responseDestino = await httpPost(`/geolocate`, {place: jsonUbicacion})
    
                if(responseDestino.data) {
                    let destinoCoordsCheck = JSON.parse(responseDestino.data);
    
                    setDestinoCoordinates({
                        lat: destinoCoordsCheck.lat,
                        lng: destinoCoordsCheck.lng
                    });
                    
                    setDone(true);
                }  else {
                    setErrorMessage('La ubicación seleccionada no es una dirección valida.');
                }
            }
    
            fetchDistance();
        }
    }*/

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit}>
                <h2 style={{ marginBottom: '15px', color: '#666666' }}>Ubicación de Destino</h2>
                {/*<Row form>
                    <Col md={8}>
                        <FormGroup>
                            <Label style={{color: '#859899', fontSize: '16px'}}>Dirección</Label>
                            <br />
                            <Input style={{width: `100%`}} value={data.direccion}  onChange={handleChange("direccion")} required>
                                <option key="0" value=""></option>
                                {estadosSelect}
                            </Input>
                        </FormGroup>
                    </Col>
                </Row>*/}
                <FormGroup>
                    <LoadScriptNext
                        id="script-loader"
                        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                        libraries={libraries}>
                            <Container>
                                <Row>
                                    <Col sm={8}>
                                        <Autocomplete
                                            onLoad={onLoadAutocomplete}
                                            onPlaceChanged={onPlacesChanged}
                                            restrictions={{country: 'mx' }}
                                            fields={['geometry.location', 'formatted_address']}
                                            types={['address']}>
                                               <FormGroup>
                                               <Label style={{ fontFamily: 'Roboto', color: '#859899' }}>Ubicación</Label>
                                                <Input
                                                    value={ubicacion}
                                                    placeholder=""
                                                    onKeyDown={handleKeyDown}
                                                    onChange={onUbicacionChange}
                                                    style={{
                                                        padding: `0 12px`,
                                                        borderRadius: `3px`,
                                                        outline: `none`,
                                                        textOverflow: `ellipses`,
                                                        left: "0%",
                                                        marginLeft: "0px" 
                                                }} required/>
                                               </FormGroup>
                                        </Autocomplete>
                                        <div style={{color: '#859899', fontSize: '12px'}}>
                                            Comienza a escribir tu dirección de destino y selecciona una ubicación del listado
                                        </div>
                                    </Col>
                                    <Col sm={4}>
                                        <FormGroup>
                                            <Label style={{color: '#859899', fontSize: '16px'}}>Número Interior</Label>
                                            <Input style={{width: `100%`}} value={data.numeroInterior} onChange={handleChange("numeroInterior")} />
                                        </FormGroup>
                                    </Col>
                                    

                                </Row>
                            </Container>
                            <GoogleMap
                            id='example-map'
                            mapContainerStyle={{
                                height: "50vh",
                                width: "100%"
                            }}
                            zoom={5}
                            center={center}
                            options={defaultMapOptions}
                            >
                            <Marker
                                onLoad={onLoadFunc}
                                position={destinoCoordinates}
                        />
                        </GoogleMap>
                    </LoadScriptNext>
                </FormGroup>
                <Row style={{marginLeft: '5px', alignItems:'center'}}>
                    <Col>
                        <FormGroup>
                            <Label style={{color: '#859899', fontSize: '16px'}}>Pisos</Label>
                            <Input type="number" style={{width: `100%`}} value={data.pisos} onChange={handleChange("pisos")} required />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label check>
                                <Input type="checkbox" value="elevadorDisponible" onChange={handleChangeElevedorDisponible()} name="facturaCheckbox" checked={data.elevadorDisponible}/>{' '}
                                <div className="Gray">Elevador Disponible</div>
                            </Label>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label check>
                                <Input type="checkbox" value="requiereVolado" onChange={handleChangeRequiereVolado()} name="facturaCheckbox" checked={data.requiereVolado}/>{' '}
                                <div className="Gray">Requiere Volado</div>
                            </Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    {
                        errorMessage ? <React.Fragment>
                            <span style={{color: 'red', fontWeight: 'bold'}}>{errorMessage}</span>
                        </React.Fragment> : null
                    }
                </Row>
                <Row style={{ alignItems: 'center', justifyContent: 'flex-end', flexWrap: 'nowrap' }}>
                    <ReturnSolidButton onClick={goBack}>REGRESAR</ReturnSolidButton>
                    <span style={{margin: '5px'}}></span>
                    <SolidButton disabled={disabledButton || !data.direccion || !done} type="submit">SIGUIENTE</SolidButton>
                </Row>
            </Form>
        </React.Fragment>
    )

}
