import React from 'react';
import { Button } from 'reactstrap';
import styles from './SolidButton.module.css'

export default function SolidButton({children, disabled, fullWidth, ...rest}) {

    return (
        <Button disabled={disabled} className={`${disabled ? styles.SolidButtonDisabled : styles.SolidButton} ${fullWidth ? styles.FullWidth : ''}`} {...rest}>{children}</Button>
    )

}