import React from 'react';
import { Button } from 'reactstrap';
import styles from './SolidButton.module.css'

export default function ReactionSolidButton({children, disabled, ...rest}) {

    return (
        <Button className={`${styles.SolidButtonDisabled} ${styles.Return}`} {...rest}>{children}</Button>
    )

}