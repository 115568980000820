import React, { useState } from 'react';
import SolidButton from '../components/SolidButton';
import ReturnSolidButton from '../components/ReturnSolidButton';
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container } from 'reactstrap'

export default function ModalCotizacionEnviada({titulo, descripcion, isOpen, onCancel, onAceptar}) {
    return (
        <Modal size="lg" style={{maxWidth: '788px', width: '80%'}} isOpen={isOpen}>
            <ModalBody>
                <Row>
                    <Col style={{fontFamily: 'Roboto', color: '#666666', fontSize: '35px'}} className="p-4 text-center">
                        {titulo}
                    </Col>
                </Row>
                <Row>
                    <Col style={{fontFamily: 'Roboto', color: '#666666', fontSize: '16px'}} className="p-4 text-center">
                        {descripcion}
                    </Col>
                </Row>
                <Row>
                    <Col className="p-4 text-center">
                        <SolidButton color="primary" onClick={onAceptar}>Aceptar</SolidButton>{' '}
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}