import React, { useState, useEffect } from 'react'
import SolidButton from '../components/SolidButton'
import ReturnSolidButton from '../components/ReturnSolidButton'
import { FormGroup, Label, Input, Form, Row, Col, Container } from 'reactstrap'

export default function Datos({onNext, onBack, dataStep}) {

    const [disabledButton, setDisabledButton] = useState(true);
    const [pasoCompleto, setPasoCompleto] = useState(false);

    const [data, setData] = useState({
        nombre: dataStep.nombre,
        email: dataStep.email,
        telefono: dataStep.telefono,
        codigo: dataStep.codigo
    })

    function handleChange(name) {
        return function(event) {
            setData({...data, [name]:event.target.value})
        }
    }

    useEffect(() => {
        if(data.nombre && data.email && data.telefono) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [data]);


    function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if(window.dataLayer) {
            window.dataLayer.push({'event': 'form-sent-Seguinte'});
        }
        
        onNext({
            component: (
                <Row>
                </Row>
            ),
            data
        });
    }

    function goBack(e) {
        e.preventDefault();
        e.stopPropagation();

        onBack({
            component: (
                <Row>
                </Row>
            ),
            data
        })
    }

    return (
        <React.Fragment>
            <Form onSubmit={handleSubmit}>
                <h2 style={{marginBottom: '15px' }}>Datos del contacto</h2>
                <FormGroup>
                    <Label style={{ fontFamily: 'Roboto', color: '#859899' }}>Nombre</Label>
                    <Input style={{marginBottom: '20px' }} value={data.nombre} onChange={handleChange('nombre')} placeholder="" required/>
                </FormGroup>
                <FormGroup>
                    <Label style={{ fontFamily: 'Roboto', color: '#859899' }}>Correo electrónico</Label>
                    <Input style={{marginBottom: '20px' }} value={data.email} type="email" onChange={handleChange('email')} placeholder="" required/>
                </FormGroup>
                <div style={{marginBottom: '45px' }}>
                    <Row xs="2">
                        <Col md={6}>
                            <FormGroup>
                                <Label style={{ fontFamily: 'Roboto', color: '#859899' }}>Teléfono</Label>
                                <Input type="number" value={data.telefono} onChange={handleChange('telefono')} placeholder="" required/>
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label style={{ fontFamily: 'Roboto', color: '#859899' }}>Código de Referido</Label>
                                <Input value={data.codigo} onChange={handleChange('codigo')} placeholder="" />
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
                <br />
                <Row>
                    <Col md={5} style={{color: '#859899', marginRight: '30px' }}>
                        Tu información está protegida por La Ley Federal de Protección de Datos Personales
                    </Col>
                    <Col className="text-md-right">
                        <SolidButton disabled={disabledButton} type="submit">SIGUIENTE</SolidButton>
                    </Col>
                </Row>
            </Form>
        </React.Fragment>
    )

}