import React, { useState } from 'react'
import styles from './SummaryCollapse.module.css';
import { Collapse, Container, Row, Col } from 'reactstrap';
import Chevron from '../iconos/Chevron.svg'

export default function SummaryCollapse({title, children}) {

    const [isOpen, setIsOpen] = useState(false);
    
    function toggle() {
        setIsOpen(!isOpen);
    }

    return (
        <Container style={{padding:0}}>
            <Row style={{cursor: 'pointer'}} onClick={toggle}>
                <Col xs="9" className="SummaryTitle">{title}</Col>
                <Col xs="3">
                    <img src={Chevron} alt="collapse" className={`${styles.Chevron} ${isOpen ? styles.ChevronOpen : ''}`}></img>
                </Col>                
            </Row>
            <div className="Separator"></div>
            <Row>
                <Collapse isOpen={isOpen} style={{width:'100%'}}>
                    <Container>
                        {children}
                    </Container>
                </Collapse>
            </Row>
        </Container>
    )

}